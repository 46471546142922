/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import moment from 'moment/moment'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import LoadingSpinner from '../../../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import {
  useStagingTimeSupervision,
  useStagingTimeSupervisionChart
} from '../../../../../hook/parental-control/useParental'
import { useSessionContex } from '../../../../../SessionContex'
import { getColorCode } from '../../../../../utils/helper'
import TimeSupervisionChart from '../../../../Charts/time-supervision-chart'
import '../styles.scss'

interface Props {
  email: string
  isCard?: boolean
  filteringDevice?: any
  dataFilter?: any
  fromDates?: any
}

const TimeSuperVisionStaging: FC<Props> = ({ email, isCard, filteringDevice, dataFilter, fromDates }) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  // pagination
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [newData, setNewData] = useState<any>([])

  // const [hours] = useState<number>(dayjs().tz(timeZone).diff(dayjs().tz(timeZone).startOf('day'), 'hour'))
  const [fromDate, setFromDate] = useState<any>(
    moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss')
  )
  const [filterLabel, setFilterLabel] = useState<string>('')

  const { data, refetch, isFetching, error } = useStagingTimeSupervision(
    fromDates ? fromDates : fromDate,
    email,
    filteringDevice,
    offset,
    limit
  )

  const {
    data: chartData,
    refetch: chartRefetch,
    isFetching: chartFetching
  } = useStagingTimeSupervisionChart(fromDates ? fromDates : fromDate, email, filteringDevice, filterLabel)

  useEffect(() => {
    if (data) {
      if (data === 401) {
        !openModal && setOpenModal(true)
      } else {
        if (data && data[0] && data[0]?.data) {
          setNewData(data[0].data)
        } else {
          setNewData([])
        }
      }
    } else {
      setNewData([])
    }
  }, [data])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    // setRowsPerPage(+event.target.value)
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
    setOffset(0)
  }
  // pagination

  const handleFilterChange = (e: any) => {
    setFilterLabel(e.target.value)
    if (e.target.value === 'thisMonth') {
      setFromDate(moment.utc(moment.tz(moment().subtract(1, 'month'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'thisWeek') {
      setFromDate(moment.utc(moment.tz(moment().subtract(7, 'days'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'today') {
      setFromDate(moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    }
  }

  useEffect(() => {
    setCurrentPage(0)
    setOffset(0)

    chartRefetch()
    !isCard && refetch()
  }, [fromDate, filteringDevice, filterLabel])

  useEffect(() => {
    if (dataFilter) {
      setFilterLabel(dataFilter)
    } else {
      setFilterLabel(filterLabel !== '' ? filterLabel : 'today')
    }
  }, [dataFilter])

  useEffect(() => {
    !isCard && refetch()
  }, [offset, limit])
  useEffect(() => {
    if (reCall) {
      if (currentPage !== 0 || offset !== 0) {
        setCurrentPage(0)
        setOffset(0)
      } else {
        !isCard && refetch()
        chartRefetch()
      }
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  const CartData = [
    {
      name: 'Today',
      value: 'today'
    },
    {
      name: 'Last 7 Days',
      value: 'thisWeek'
    },
    {
      name: 'Last 30 Days',
      value: 'thisMonth'
    }
  ]
  const calculateUnix = (dateTime: any) => {
    const dateTimeString: any = moment.utc(dateTime).local()

    // Convert the date-time string to a Date object
    const dateObj = new Date(dateTimeString)

    // Get the Unix timestamp (in seconds) from the Date object
    const unixTimestamp = Math.floor(dateObj.getTime() / 1000)

    return unixTimestamp
  }
  const calculateDuration = (end_datetime: any, start_datetime: any) => {
    // Convert timestamps to milliseconds
    const startTime = calculateUnix(start_datetime) * 1000
    const endTime = calculateUnix(end_datetime) * 1000

    // Calculate the difference in milliseconds
    const durationInMilliseconds = endTime - startTime

    // Convert the duration to hours and minutes
    const hours = Math.floor(durationInMilliseconds / 3600000)
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000)
    const seconds = Math.floor((durationInMilliseconds % 60000) / 1000)

    return `${hours !== 0 ? `${hours}h ` : ''} ${minutes !== 0 ? `${minutes}m ` : ''} ${seconds}s`
  }

  return (
    <div
      className={`time-supervision-wrapper bg-white rounded-[10px] min-h-[532px] ${
        isCard ? 'iscard' : 'w-full'
      } pt-[26px]  px-[28px]`}
    >
      {isCard && (
        <div className='inline-flex w-full justify-between items-center time-supervision-inner-wrapper'>
          <span className='text-[24px] font-sf-bold time-supervision-title'>Time Supervision ( in minutes )</span>

          <div className='w-40 mt-2'>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={filterLabel}
                label='Filter'
                onChange={handleFilterChange}
                className='text-uppercase'
                data-testid='testid_time_supervision_filter'
              >
                {CartData.map((option: any, index: number) => {
                  return (
                    <MenuItem
                      value={option.value}
                      className='text-uppercase'
                      key={`option_${index}_${option}`}
                      data-testid={`testid_time_supervision_${option.value}`}
                    >
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      )}

      <div className='w-full mt-[12px] pb-[44px] chart-main-block' style={{ height: '60%' }}>
        {chartFetching ? (
          <div
            style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <LoadingSpinner />
          </div>
        ) : chartData === null || (chartData && chartData?.length === 0) || error !== null ? (
          <Box color={getColorCode('redToBlue')} textAlign={'center'} data-testid='testid_dashboard_time_chart_no_data'>
            Time chart data not found
          </Box>
        ) : (
          <>
            <TimeSupervisionChart data={chartData} />
          </>
        )}
      </div>

      {!isCard && (
        <>
          <table className='pc-table'>
            <thead>
              <tr>
                <td>Device name</td>
                <td>Duration</td>
                <td>Start time</td>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td colSpan={3}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <LoadingSpinner />
                    </Box>
                    {/* <span className='animate-pulse mx-auto'>Loading</span> */}
                  </td>
                </tr>
              )}

              {!isFetching &&
                newData &&
                newData.length !== 0 &&
                newData
                  ?.sort((a: any, b: any) => {
                    if (a.start_datetime > b.start_datetime) return -1
                    if (a.start_datetime < b.start_datetime) return 1
                    return 0
                  })
                  // ?.slice(offset, offset + limit)
                  .map((row: any) => {
                    // const duration = moment.duration(row.end_datetime - row.start_datetime, 'seconds').humanize()

                    // const time = moment.unix(row.start_datetime).local().format('D.M.YYYY hh:mm A').split(' ')
                    const time = moment
                      .utc(row.start_datetime)
                      .local()
                      .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                    return (
                      <tr>
                        <td>{row.device_name !== '' ? row.device_name : 'unknown'}</td>
                        <td>{calculateDuration(row.end_datetime, row.start_datetime)}</td>
                        <td>
                          {time}
                          {/* {time[0]}
                          <br />
                          {time[1] + ' ' + time[2]} */}
                        </td>
                      </tr>
                    )
                  })}
              {!isFetching && newData?.length === 0 && (
                <tr>
                  <td
                    colSpan={3}
                    style={{ textAlign: 'center', color: getColorCode('redToBlue') }}
                    data-testid='testid_time_suppervision_no_data'
                  >
                    No Data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!isFetching && newData && newData.length !== 0 && (
            <TablePagination
              sx={{ marginRight: 0 }}
              rowsPerPageOptions={[3, 6, 10, 20, 50]}
              component='div'
              count={(data && data[1]?.total?.split('/')[1]) || 0}
              rowsPerPage={limit}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </div>
  )
}

export default TimeSuperVisionStaging
