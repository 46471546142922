import { useQuery } from '@tanstack/react-query'
import { groupServerUrl, groupSufix } from '../../app-config'
import xcpemSec from '../../axiosInstances/xcpemSec'

const access_token = localStorage.getItem('access_token')

export const useGetGroupLists = () => {
  const fetchGroups = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/groups`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching group list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getGroupLists'],
    queryFn: fetchGroups,
    enabled: false, // Disabled by default; must be triggered manually
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const handleAddGroupData = async (payload: any) => {
  let url = `${groupServerUrl}/${groupSufix}/groups?name=${payload?.groupName
    ?.trim()
    ?.replace(/\s+/g, ' ')}&whitelabel=${payload?.whitelabel?.trim()?.replace(/\s+/g, ' ')}`

  const response = await xcpemSec.post(
    `${url}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }
  )
  return response
}

export const handleUpdateGroupData = async (payload: any) => {
  let url = `${groupServerUrl}/${groupSufix}/groups?id=${payload?.id}`

  const response = await xcpemSec.put(
    `${url}`,
    {
      name: payload?.groupName?.trim()?.replace(/\s+/g, ' ') || '',
      whitelabel: payload?.whitelabel?.trim()?.replace(/\s+/g, ' ') || ''
    },
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }
  )
  return response
}

export const handleDeleteGroupData = async (id: any) => {
  let url = `${groupServerUrl}/${groupSufix}/groups?id=${id}`

  const response = await xcpemSec.delete(url)
  return response
}

export const useGetBatchLists = ({ group_id }: { group_id?: number | string }) => {
  const params = new URLSearchParams()
  if (group_id) params.append('group_id', `${group_id}`)

  const fetchBatches = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/batches?${params.toString()}`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching group list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getBatchLists'],
    queryFn: fetchBatches,
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGetFirmwareLists = () => {
  const fetchFirmwareLists = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/firmwares`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching group list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getFirmwareLists'],
    queryFn: fetchFirmwareLists,
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const handleAddBatchData = async (payload: any) => {
  let url = `${groupServerUrl}/${groupSufix}/batches`

  const response = await xcpemSec.post(
    `${url}`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }
  )
  return response
}

export const handleUpdateBatchData = async (payload: any) => {
  let url = `${groupServerUrl}/${groupSufix}/groups?id=${payload?.id}`

  const response = await xcpemSec.put(
    `${url}`,
    {
      name: payload?.groupName
    },
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }
  )
  return response
}

export const handleDeleteBatchData = async (id: any) => {
  let url = `${groupServerUrl}/${groupSufix}/batches?id=${id}`

  const response = await xcpemSec.delete(url)
  return response
}

export const handleAddGateways = async ({ gwuid, groupId }: { gwuid: string[]; groupId: number }) => {
  let url = `${groupServerUrl}/${groupSufix}/groups/${groupId}/gateways`

  const response = await xcpemSec.post(
    `${url}`,
    { gwuid: gwuid },
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }
  )
  return response
}

export const useGetUserLists = ({ group_id }: { group_id?: number | string }) => {
  const fetchUsers = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/groups/${group_id}/gateways`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data?.gateways || []
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching group list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getUserLists'],
    queryFn: fetchUsers,
    enabled: false, // Disabled by default; must be triggered manually
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const handleUpdateUserRole = async ({
  gw_uid,
  is_dev,
  is_vip,
  is_friendly
}: {
  gw_uid: string
  is_dev: boolean
  is_vip: boolean
  is_friendly: boolean
}) => {
  let url = `${groupServerUrl}/${groupSufix}/gateways/${gw_uid}/role?is_dev=${is_dev}&is_vip=${is_vip}&is_friendly=${is_friendly}`

  const response = await xcpemSec.put(
    `${url}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }
  )
  return response
}

export const handleDeleteUserData = async ({ group_id, id }: { group_id: number; id: any }) => {
  let url = `${groupServerUrl}/${groupSufix}/groups/${group_id}/gateways?member_ids=${id}`

  const response = await xcpemSec.delete(url)
  return response
}

export const useGetSegmentsLists = ({ batch_id }: { batch_id?: number | string }) => {
  const fetchGetSegmentsLists = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/batches/${batch_id}/segments`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data || []
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching segment list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getSegmentsLists'],
    queryFn: fetchGetSegmentsLists,
    enabled: false, // Disabled by default; must be triggered manually
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGetBatchUpgradeJobsList = ({ batch_id }: { batch_id?: number | string }) => {
  const fetchGetBatchUpgradeJobsList = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/batches/${batch_id}/jobs`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data || []
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching job upgrade list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getBatchUpgradeJobsList'],
    queryFn: fetchGetBatchUpgradeJobsList,
    enabled: false, // Disabled by default; must be triggered manually
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGetBatchStatus = ({ batch_id }: { batch_id?: number | string }) => {
  const fetchGetBatchStatus = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/batches/${batch_id}/stats`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching job upgrade list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getBatchStatus'],
    queryFn: fetchGetBatchStatus,
    enabled: false, // Disabled by default; must be triggered manually
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGetUserHistory = ({ gwuid }: { gwuid?: string }) => {
  const fetchGetUserHistory = async () => {
    const endpoint = `${groupServerUrl}/${groupSufix}/gateways/${gwuid}/upgrade-history`
    // try {
    const response = await xcpemSec.get(endpoint)
    return response.data || []
    // } catch (error: any) {
    //   if (error?.response?.status === 401) {
    //     return Promise.reject(error?.response?.status)
    //   } else {
    //     notifyError(`Error fetching user history list: ${error?.response?.data?.message || error?.message}`)
    //     return Promise.reject(error)
    //   }
    // }
  }

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery({
    queryKey: ['getUserHistory'],
    queryFn: fetchGetUserHistory,
    enabled: false, // Disabled by default; must be triggered manually
    cacheTime: 0,
    staleTime: 0,
    retry: false
  })

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
