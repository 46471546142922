import { Box, CardContent, Paper, Skeleton, styled, Typography } from '@mui/material'
import { ArrowDown, ArrowUp } from 'iconsax-react'
import React from 'react'
import NetWorkLogo from '../../common-images/app-logo/HitronHomePgTilesIcons/NetWorkLogo'
import { convertMBSpeedParameter, getColorCode } from '../../utils/helper'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027'
  }),
  borderRadius: '1.5rem'
}))

interface CellularCardProps {
  isLoading: boolean
  title: string
  cellularUsageSummaryData: {
    upload_data_mb: number
    download_data_mb: number
    device_count: number
  }
  onlineDevice?: number
}

const CellularCard = ({ isLoading, cellularUsageSummaryData, title, onlineDevice }: CellularCardProps) => {
  return (
    <React.Fragment>
      <Item>
        <CardContent style={{ paddingBottom: 16 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant='h3'
              color={'#2C2FEF'}
              height={'120px'}
              mt={'0.6rem'}
              data-testid='testid_all_data_usages'
            >
              {isLoading && title !== 'Reports' ? (
                <Skeleton variant='rounded' width={'150px'} height={'50px'} sx={{ mt: '0.6rem' }} />
              ) : (
                <>
                  <p
                    className='text-[2rem] flex items-center mb-1 font-bold'
                    style={{ color: getColorCode('UploadUsage') }}
                  >
                    <ArrowUp size='25' color={getColorCode('UploadUsage')} />
                    {convertMBSpeedParameter(cellularUsageSummaryData?.upload_data_mb, 2)}
                  </p>
                  <p
                    className='text-[2rem] flex items-center font-bold'
                    style={{ color: getColorCode('DownloadUsage') }}
                  >
                    <ArrowDown size='25' color={getColorCode('DownloadUsage')} />
                    {convertMBSpeedParameter(cellularUsageSummaryData?.download_data_mb, 2)}
                  </p>
                </>
              )}
            </Typography>
            <Box sx={{ marginTop: '1rem' }}>
              <Typography sx={{ color: '#00000080', fontSize: '16px', fontWeight: 500 }}>in last 24 hours</Typography>
              <Typography sx={{ color: '#00000080', fontSize: '16px', fontWeight: 500 }}>
                by {cellularUsageSummaryData?.device_count || onlineDevice || 0} devices
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography color='#000' sx={{ mt: 1, fontWeight: 500, fontSize: '1.3rem', letterSpacing: '0.025em' }}>
              {title}
            </Typography>
            <NetWorkLogo />
          </Box>
        </CardContent>
      </Item>
    </React.Fragment>
  )
}

export default CellularCard
