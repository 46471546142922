import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { Alert } from '../models/Xcpem'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { useState } from 'react'

export const useAllActiveAlertsForGateway = (
  gw_uid: any
): {
  data: Alert[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)

  const gwuid: any = !!gw_uid ? gw_uid : localStorage.getItem('gwuid')
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gatewayalerts'],
    async () => {
      // if (!gwuid) return undefined

      const endpoint = `/webapp_dashboard_alerts_log?gw_uid=ilike.${gwuid}&and=(is_acknowledged.eq.false,is_resolved.eq.false)`
      try {
        const response = await xcpemSec.get<Alert[]>(endpoint)
        return response.status === 401 ? response : response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Alert log :${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['use-Wifi-QoE'] as readonly unknown[])

    refetch()
  }

  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
