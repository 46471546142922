// import { FC, useEffect, useState } from 'react'
// import { ConnectedDevice } from '../../../models/Xcpem'
// import './styles.scss'
// import { Switch, Modal } from 'antd'
// import { Button } from '@mui/material'
// import { useDeletePCSetting } from '../../../hook/parental-control'
// import { getColorCode } from '../../../utils/helper'
// import { Close } from '@mui/icons-material'

// interface Props {
//   device: ConnectedDevice | any
//   setDeviceCall?: any
// }

// const DeviceDetails: FC<Props> = ({ device, setDeviceCall }) => {
//   const [displayData, setDisplayData] = useState<any>()

//   const { mutate, isSuccess } = useDeletePCSetting()

//   const [isOpen, setIsOpen] = useState<boolean>(false)

//   useEffect(() => {
//     setDisplayData(device)
//   }, [device])
//   const changeStatus = () => {
//     setIsOpen(true)
//   }
//   const onSubmit = () => {
//     mutate(device.device_id)
//   }
//   useEffect(() => {
//     if (isSuccess) {
//       setDeviceCall(true)
//       setTimeout(() => {
//         setIsOpen(false)
//         setDeviceCall(false)
//       }, 1000)
//     }
//   }, [isSuccess])
//   return (
//     <div className='bg-white rounded-[10px] py-[30px] px-[33px] flex-1 w-full device-detail-wrapper'>
//       <h1>Device Details</h1>

//       <table className='device-info-table min-w-[600px]'>
//         <tbody>
//           <tr>
//             <td>Device Name:</td>
//             <td>
//               <input
//                 type='text'
//                 className='border-2 rounded-[10px] p-2 w-full cursor-not-allowed bg-gray-200'
//                 value={displayData?.device_name}
//                 disabled={true}
//               />
//             </td>
//           </tr>
//           <tr>
//             <td>Device Type:</td>
//             <td>{displayData?.device_type}</td>
//           </tr>
//           <tr>
//             <td>Is blockable?:</td>
//             <td>{displayData?.is_device_blockable ? 'Yes' : 'No'}</td>
//           </tr>
//           <tr>
//             <td>Is connected?:</td>
//             <td>{displayData?.is_active ? 'Yes' : 'No'}</td>
//           </tr>
//           <tr>
//             <td>Manufacturer:</td>
//             <td>{displayData?.manufacturer_name}</td>
//           </tr>
//           <tr>
//             <td>Parental control:</td>
//             <td>
//               {displayData?.is_pc_active ? (
//                 <button
//                   onClick={changeStatus}
//                   type='button'
//                   className=' text-white rounded-[5px] cursor-pointer exitbutton'
//                   style={{ background: getColorCode('infoBlue') }}
//                 >
//                   Disable Limits
//                 </button>
//               ) : (
//                 'Not Active'
//               )}
//               {/* <label className={`relative inline-flex items-center mr-5`}>
//                 <Switch
//                   checked={displayData?.is_pc_active}
//                   disabled={!displayData?.is_pc_active}
//                   onChange={changeStatus}
//                 /> */}
//               {/* <input
//                   type='checkbox'
//                   value='1'
//                   className='sr-only peer'
//                   onChange={event => {}}
//                   defaultChecked={device?.is_pc_active}
//                   disabled={true}
//                 />
//                 <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-alerts-closed  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#3395FF] cursor-not-allowed"></div> */}
//               {/* </label> */}
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       <Modal
//         open={isOpen}
//         footer={null}
//         closable={false}
//         width={400}
//         wrapClassName={'reset_modal_wrapper'}
//         centered={true}
//       >
//         <div className='reset_modal_body_wrapper device-tab'>
//           <div className='text-end mb-2'>
//             <Close className='cursor-pointer' onClick={() => setIsOpen(false)} />
//           </div>
//           <div className='mb-2 row-gap-1 text-center'>
//             <div className='mb-3 mt-1 text-base'>
//               Please confirm that you want to Disable all Parental Control limits on this Device.
//             </div>
//             <div>
//               <Button
//                 onClick={onSubmit}
//                 variant='contained'
//                 size='medium'
//                 sx={{
//                   marginRight: '10px',
//                   backgroundColor: getColorCode('blueBlue'),
//                   '&:hover': {
//                     backgroundColor: getColorCode('blueBlue')
//                   }
//                 }}
//               >
//                 Disable
//               </Button>
//               {/* <Button
//                 onClick={() => setIsOpen(false)}
//                 size='medium'
//                 variant='outlined'
//                 sx={{
//                   color: getColorCode('blueBlue'),
//                   borderColor: getColorCode('blueBlue')
//                 }}
//               >
//                 Cancel
//               </Button> */}
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </div>
//   )
// }

// export default DeviceDetails

import { commonCondition } from '../../../lib/utils'
import DeviceDetailsCommon from '../common/device-details/device-details'
import DeviceDetailsStaging from '../staging/device-details/device-details'

const DeviceDetails: React.FC<any> = ({ device, setDeviceCall }) => {
  return (
    <>
      {commonCondition ? (
        <DeviceDetailsStaging device={device} setDeviceCall={setDeviceCall} />
      ) : (
        <DeviceDetailsCommon device={device} setDeviceCall={setDeviceCall} />
      )}
    </>
  )
}

export default DeviceDetails
