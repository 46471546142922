import { ELEPcommon } from '../lib/utils'

export const OpenWiFiBaseUrl = 'https://wifi.optimcloud.com'
export const ProvisioningUrl = 'http://localhost:800'

export const SECURITY_PORT = 16001
export const GATEWAY_PORT = 16002
export const FIRMWARE_MANAGEMENT_PORT = 16004
export const PROVISIONING_PORT = 16005
export const SUBSCRIBER_PORT = 16006
export const RRM_PORT = 16789
export const ANALYTICS_PORT = 16009

export const apiUrl = `https://${process.env.REACT_APP_IOTCORE}`

export const readOnlyApiUrl = `https://${process.env.REACT_APP_IOTREAD}`

export const cyberApiUrl = `https://${process.env.REACT_APP_CYBER}`

export const reportsApiUrl = `https://${process.env.REACT_APP_IOTREPORT}`

export const groupServerUrl = `https://${process.env.REACT_APP_GROUPS}`
export const groupSufix = 'api/v4/fwupgrade'

export const KEYCLOAK_CONFIG = {
  URL: `https://${process.env.REACT_APP_SSO}`,
  REALM: `${process.env.REACT_APP_REALM}`,
  CLIENT_ID: 'Web App Client',
  CLIENT_SECRET: `${process.env.REACT_APP_CLIENT_SECERET}`
}

export const routerPlatformUrl = 'https://account.xunison.com'
export const smartHomePlatformUrl = 'https://account.xunison.com/dashboard?gtway='
export const v4prifix = '/api/v4/iot'

export const versionCheckURL = `${v4prifix}/app/version/check?application=web&version=${
  ELEPcommon
    ? '1.0.1'
    : process.env.REACT_APP_REALM !== 'Hitron'
    ? process.env.REACT_APP_REALM === 'Customer'
      ? '2.8.0'
      : '2.7.0'
    : '2.6.0'
}`
