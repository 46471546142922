/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import AnimatedLayout from '../../Layouts/AnimatedLayout'
import CustomerTable from '../../components/Customer-table'
import DeviceTable from '../../components/Device-table'
import { BreadcrumbContext } from '../../contexts/BreadcrumbContext'
import { getCustomerStatisticsCount } from '../../redux/customerStatistics/customerStatistics'
import { CustomersRootState } from '../../redux/customerStatistics/types'
import { getDeviceCount } from '../../redux/deviceStatistics/deviceStatistics'
import { RootState } from '../../redux/deviceStatistics/types'
import { getColorCode } from '../../utils/helper'

import { Box, CardContent, Paper, Skeleton, Stack, styled, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import moment from 'moment'
import { useSessionContex } from '../../SessionContex'
import { versionCheckURL } from '../../app-config'
import xcpemSec from '../../axiosInstances/xcpemSec'
import Customer from '../../common-images/app-logo/card-logo/Customer'
import DevicesLogo from '../../common-images/app-logo/card-logo/DevicesLogo'
import ReStatus from '../../common-images/app-logo/customer-images/ReStatus'
import SessionTimeOutModal from '../../components/SessionTimeOutModal'
import { useGetCustomerAndCount } from '../../hook/useAllCustomers'
import { useDashboardCellularSummary } from '../../hook/useCellularUsageSummary'
import { StagingScallingCustomer } from '../../lib/utils'
import CellularModal from '../Customer/Overview/CellularUsage/CellularModal'
import VersionCheckModel from '../Login/VersionCheckModel'
import ReportsPage from '../Reports'
import CellularCard from './CellularCard'
import CommonDashboardCard from './CommonDashboardCard'
import NetworkBar from './NetworkBar'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027'
  }),
  borderRadius: '1.5rem'
}))

const Customers = () => {
  const defaultUserData = useSelector((state: RootState) => state.user)
  const whiteLable: string | null | undefined = defaultUserData?.whiteLabel
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const reportRef = useRef<HTMLDivElement | null>(null)

  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const { updateBreadcrumbData, updateFilters } = useContext(BreadcrumbContext)

  const deviceStats: any[] = useSelector((state: RootState) => state.deviceStatistics.data) || []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceCount: any = useSelector((state: RootState) => state.deviceStatistics.deviceCount) || []

  // const deviceStatsCount: any[] = useSelector((state: RootState) => state.deviceStatistics.totalData) || []
  const deviceStatsCountLoading = useSelector((state: RootState) => state.deviceStatistics.countLoading) || false
  const deviceStatsTableLoading = useSelector((state: RootState) => state.deviceStatistics.tableLoading) || false
  const customersStatsLoading = useSelector((state: CustomersRootState) => state.customerStatistics.loading) || false
  const customersStatsTotalData = useSelector((state: CustomersRootState) => state.customerStatistics.totalData) || []

  const { data: countData, isFetching, refetch } = useGetCustomerAndCount()
  const {
    data: cellularUsageSummaryData,
    isFetching: cellularUsageSummaryFetching,
    refetch: cellularUsageSummaryFetch
  } = useDashboardCellularSummary(moment().subtract(24, 'hours').toISOString())

  // const { data: onlineData, isFetching: onLineFetching, refetch: onLineRefetch } = useGetOnlineCount()

  const [filteringValue, setFilteringValue] = useState<string>('')
  const [showVerifyVersionOpen, setShowVerifyVersionOpen] = useState(false)
  // const [onlineDevices, setOnlineDevices] = useState<any>(deviceCount && deviceCount[0]?.online_devices_count)
  // const [offlineDevices, setOfflineDevices] = useState<any>(deviceCount && deviceCount[0]?.offline_devices_count)
  // const [needUpdate, setNeedUpdate] = useState<any>(deviceCount && deviceCount[0]?.update_required_devices_count)
  // const [notRegistered, setNotRegistered] = useState<any>(deviceCount && deviceCount[0]?.not_registered_devices_count)

  const tableData = (filterValue: string) => {
    switch (filterValue) {
      case 'AllCustomer':
        return deviceCount[0]?.all_white_label_count
      case 'AllDevices':
        return deviceCount[0]?.all_devices_count
      case 'Online':
        return deviceCount[0]?.online_devices_count
      case 'Offline':
        return deviceCount[0]?.offline_devices_count
      case 'notRegistered':
        return deviceCount[0]?.not_registered_devices_count
      case 'needUpdate':
        return deviceCount[0]?.update_required_devices_count
    }
  }

  const apiCallFun = async () => {
    if (filteringValue === 'AllCustomer') {
      refetch()
      const res = StagingScallingCustomer
        ? await dispatch(getCustomerStatisticsCount(whiteLable))
        : await dispatch(getCustomerStatisticsCount())
      if (res === 401) {
        !openModal && setOpenModal(true)
      }
    }
    const deviceCountRes = await dispatch(getDeviceCount())
    if (deviceCountRes === 401) {
      !openModal && setOpenModal(true)
    }
  }

  useEffect(() => {
    updateBreadcrumbData([])
    updateFilters([])

    // onLineRefetch()

    refetch()
    if (process.env.REACT_APP_REALM !== 'Hitron' && process.env.REACT_APP_REALM !== 'entpnt')
      cellularUsageSummaryFetch()
  }, [])

  useEffect(() => {
    if (filteringValue !== '') {
      updateBreadcrumbData([])
      updateFilters([])
      apiCallFun()
    }
  }, [filteringValue])

  useEffect(() => {
    if (StagingScallingCustomer) {
      const val: string = 'AllCustomer'
      setFilteringValue(val)
    } else {
      const val: string = !whiteLable || whiteLable === 'undefined' ? 'AllCustomer' : 'AllDevices'
      setFilteringValue(val)
    }
  }, [whiteLable])

  useEffect(() => {
    if (reCall) {
      updateBreadcrumbData([])
      updateFilters([])
      apiCallFun()
      refetch()
      // onLineRefetch()
    }
  }, [reCall])
  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  const handleFilteringValueChange = (field: string) => {
    setFilteringValue(field)

    if (field === 'reports') {
      setTimeout(() => {
        reportRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 100) // Small delay to ensure the DOM updates before scrolling
    }
  }
  useEffect(() => {
    if (countData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [countData])

  const handleCheckVersionFunction = async () => {
    try {
      const response = await xcpemSec.get(versionCheckURL)
      if (!response.data?.valid) {
        setShowVerifyVersionOpen(true)
      } else {
        setShowVerifyVersionOpen(false)
      }
    } catch (error: any) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    handleCheckVersionFunction()
  }, [])

  const [cellularModalOpen, setCellularModalOpen] = useState<boolean>(false)

  return (
    <AnimatedLayout>
      {openModal && <SessionTimeOutModal />}
      <div className='flex bg-white text-lg font-bold text-center border-b-2 items-center sticky top-0 left-0 z-10'>
        <div className='pt-3 w-full pb-3 bg-white text-lg font-bold text-center'>Managed CPE Dashboard</div>
        {process.env.REACT_APP_REALM === 'Hitron-staging' && (
          <div
            className='px-4 border-l-2 flex items-center gap-2 cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('reports')
            }}
            data-testid={`testid_${'reports'}_button`}
          >
            Reports
            <ReStatus size={25} />
          </div>
        )}
      </div>

      <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#f8f9fa' }}>
        <Grid container spacing={3}>
          <Grid
            size={'grow'}
            sx={{ borderRadius: '1rem' }}
            className='cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('AllCustomer')
            }}
            data-testid={`testid_${
              defaultUserData?.isMSOPortalAdmin || defaultUserData?.isMSOSuperAdmin ? 'my_mso' : 'all'
            }_button`}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'AllCustomer' ? getColorCode('infoBlue') : 'transparent'}`,
              borderRadius: '1.5rem'
            }}
          >
            <CommonDashboardCard
              className={`AllCustomer${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
              count={
                defaultUserData?.isMSOPortalAdmin || defaultUserData?.isMSOSuperAdmin
                  ? 1
                  : deviceCount[0].all_white_label_count || 0
              }
              title={defaultUserData?.isMSOPortalAdmin || defaultUserData?.isMSOSuperAdmin ? 'My MSO' : 'All MSOs'}
              countColor='black'
              icon={<Customer />}
              isLoading={deviceStatsCountLoading || customersStatsLoading}
              testId='testid_all_customers'
            />
          </Grid>
          <Grid
            size='grow'
            sx={{ borderRadius: '1rem' }}
            className='cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('AllDevices')
            }}
            data-testid={`testid_${process.env.REACT_APP_MODE === 'hitron' ? 'all' : 'all_devices'}_button`}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'AllDevices' ? getColorCode('infoBlue') : 'transparent'}`,
              borderRadius: '1.5rem'
            }}
          >
            <CommonDashboardCard
              count={deviceCount[0].all_devices_count || 0}
              title='All Devices'
              countColor='black'
              icon={<DevicesLogo />}
              isLoading={deviceStatsCountLoading || customersStatsLoading}
              testId='testid_all_devices'
            />
          </Grid>
          <Grid
            size={process.env.REACT_APP_REALM !== 'Hitron' && process.env.REACT_APP_REALM !== 'entpnt' ? 7 : 4}
            className='cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('Online')
            }}
            data-testid={`testid_${process.env.REACT_APP_MODE === 'hitron' ? 'online' : 'online_devices'}_button`}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'Online' ? getColorCode('greenToGreen') : 'transparent'}`,
              borderRadius: '1.5rem'
            }}
          >
            <Item>
              <CardContent
                style={{ paddingBottom: 16 }}
                className={`Online${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
              >
                <Grid container spacing={3}>
                  <Grid
                    size={process.env.REACT_APP_REALM !== 'Hitron' && process.env.REACT_APP_REALM !== 'entpnt' ? 4 : 6}
                  >
                    <Typography
                      variant='h3'
                      color={'#21ce7d'}
                      height={'100px'}
                      mt={'0.6rem'}
                      data-testid='testid_online_devices'
                    >
                      {(deviceStatsCountLoading || customersStatsLoading) && filteringValue !== 'Reports' ? (
                        <Skeleton variant='rounded' width={'150px'} height={'50px'} sx={{ mt: '0.6rem' }} />
                      ) : (
                        deviceCount[0].online_devices_count || 0
                      )}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography
                        color='#000'
                        sx={{ mt: 1, fontWeight: 500, fontSize: '1.3rem', letterSpacing: '0.025em' }}
                      >
                        Online Devices
                      </Typography>
                      <DevicesLogo color='#21ce7d' />
                    </Box>
                  </Grid>
                  {process.env.REACT_APP_REALM !== 'Hitron' && process.env.REACT_APP_REALM !== 'entpnt' && (
                    <Grid size={4} sx={{ display: { md: 'none', lg: 'block' } }}></Grid>
                  )}
                  <Grid size='grow' borderLeft={'2px solid grey'} paddingLeft={'1rem'}>
                    <Stack direction='row' alignItems={'center'} height={'100%'} width={'100%'}>
                      <Stack direction='column' width={'100%'}>
                        <NetworkBar name={'LTE'} color='#21ce7d' count={deviceCount[0]?.online_devices_lte_count} />
                        <NetworkBar
                          name={'ETH'}
                          color='#21ce7d'
                          count={deviceCount[0]?.online_devices_ethernet_count}
                        />
                        <NetworkBar
                          name={'ETH (5G)'}
                          color='#21ce7d'
                          count={deviceCount[0]?.online_devices_ethernet_lte_count}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={'1.5rem'}>
          {process.env.REACT_APP_REALM !== 'Hitron' && process.env.REACT_APP_REALM !== 'entpnt' && (
            <Grid
              size={5}
              className='cursor-pointer'
              onClick={() => {
                if (cellularUsageSummaryData?.upload_data_mb || cellularUsageSummaryData?.download_data_mb)
                  setCellularModalOpen(true)
              }}
            >
              <CellularCard
                isLoading={deviceStatsCountLoading || customersStatsLoading || cellularUsageSummaryFetching}
                title='Cellular Data Usage'
                cellularUsageSummaryData={cellularUsageSummaryData}
                onlineDevice={deviceCount[0].online_devices_count}
              />
            </Grid>
          )}
          <Grid
            size='grow'
            sx={{ borderRadius: '1rem' }}
            className='cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('Offline')
            }}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'Offline' ? '#ffbf00' : 'transparent'}`,
              borderRadius: '1.5rem'
            }}
            data-testid={`testid_${process.env.REACT_APP_MODE === 'hitron' ? 'offline' : 'offline_devices'}_button`}
          >
            <CommonDashboardCard
              count={deviceCount[0].offline_devices_count || 0}
              title='Offline Devices'
              countColor='#ffbf00'
              icon={<DevicesLogo color='#ffbf00' />}
              isLoading={deviceStatsCountLoading || customersStatsLoading}
              className={`Offline${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
              testId='testid_offline_devices'
            />
          </Grid>
          <Grid
            size='grow'
            sx={{ borderRadius: '1rem' }}
            className='cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('notRegistered')
            }}
            data-testid={`testid_${
              process.env.REACT_APP_MODE === 'hitron' ? 'not_registered' : 'not_registered_devices'
            }_button`}
            style={{
              height: '100%',
              border: `2px solid ${
                filteringValue === 'notRegistered' ? getColorCode('lightblueToGray') : 'transparent'
              }`,
              borderRadius: '1.5rem'
            }}
          >
            <CommonDashboardCard
              className={`notRegistered${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
              count={deviceCount[0].not_registered_devices_count || 0}
              title={
                <span>
                  Not Registered <p> Devices</p>
                </span>
              }
              countColor='grey'
              isLoading={deviceStatsCountLoading || customersStatsLoading}
              icon={<DevicesLogo color='grey' />}
              testId='testid_not_registered_devices'
            />
          </Grid>
          <Grid
            size='grow'
            sx={{ borderRadius: '1rem' }}
            className='cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('needUpdate')
            }}
            data-testid={`testid_${
              process.env.REACT_APP_MODE === 'hitron' ? 'required_update' : 'update_available'
            }_button`}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'needUpdate' ? getColorCode('darkGrayToGray') : 'transparent'}`,
              borderRadius: '1.5rem'
            }}
          >
            <CommonDashboardCard
              className={`needUpdate${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
              count={deviceCount[0].update_required_devices_count || 0}
              title='Update Available Devices'
              isLoading={deviceStatsCountLoading || customersStatsLoading}
              countColor='grey'
              icon={<DevicesLogo color='grey' />}
              testId='testid_update_avilable'
            />
          </Grid>
        </Grid>
      </Box>

      <div className=' '>
        <div className='' style={{ width: '100%' }}>
          {filteringValue === 'reports' ? (
            <div ref={reportRef}>
              <ReportsPage />
            </div>
          ) : filteringValue === 'AllCustomer' ? (
            <CustomerTable
              filteringValue={filteringValue}
              customersStats={customersStatsTotalData}
              customersStatsLoading={customersStatsLoading}
              count={countData ? countData : []}
            />
          ) : (
            <DeviceTable
              filteringValue={filteringValue}
              deviceStats={deviceStats}
              deviceStatsLoading={deviceStatsTableLoading}
              count={tableData(filteringValue)}
            />
          )}
        </div>
      </div>
      <VersionCheckModel isOpen={showVerifyVersionOpen} setShowVerifyVersionOpen={setShowVerifyVersionOpen} />
      <CellularModal
        cellularModalOpen={cellularModalOpen}
        setCellularModalOpen={setCellularModalOpen}
        isDashboard={true}
      />
    </AnimatedLayout>
  )
}

export default Customers
