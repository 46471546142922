import { Box, Typography } from '@mui/material'
import AppLogo from '../../../common-images/app-logo/logo'
import EarthLinkLogo from '../../../common-images/earthlink-logo/earthlink-logo-2.png'
import EntryPointLogo from '../../../common-images/entry-point-logo/entry-point-logo-1.png'
import { customerScalling, ELEPcommon } from '../../../lib/utils'
import { getColorCode } from '../../../utils/helper'
import hitronHeaderImg from './HITRON-pb-lockup-rgb.svg'

const FooterSection = ({ className = 'bg-white' }) => {
  return (
    <Box
      className={className}
      position={'sticky'}
      bottom={0}
      left={0}
      width={'100%'}
      padding={'10px 20px'}
      zIndex={'9'}
      borderTop={'2px solid #eff2fc'}
    >
      <Box
        className='font-bold'
        textAlign={'right'}
        paddingRight={5}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* Powered by Hitron */}
        <Box>
          <Typography color={getColorCode('infoBlue')} fontWeight={600}>
            Version{' '}
            {ELEPcommon
              ? '1.0.1'
              : process.env.REACT_APP_REALM !== 'Hitron'
              ? process.env.REACT_APP_REALM === 'Customer'
                ? '2.8.0'
                : '2.7.0'
              : '2.6.0'}
          </Typography>
        </Box>
        {customerScalling && <AppLogo size={'150'} />}
        {process.env.REACT_APP_MODE === 'hitron' && (
          <img src={hitronHeaderImg} alt='hitron-logo' width={'130px'} style={{ marginLeft: 5 }} />
        )}
        {process.env.REACT_APP_REALM === 'earthlink' && (
          <img
            src={EarthLinkLogo}
            alt='eartlink-logo'
            width={'150px'}
            height={'40px'}
            style={{ marginLeft: 5, height: '40px' }}
          />
        )}
        {process.env.REACT_APP_REALM === 'entpnt' && (
          <img
            src={EntryPointLogo}
            alt='entrypoint-logo'
            width={'160px'}
            height={'50px'}
            style={{ marginLeft: 5, height: '50px' }}
          />
        )}
      </Box>
    </Box>
  )
}

export default FooterSection
