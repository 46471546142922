import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../../axiosInstances/xcpemSec'
import { notifyError } from '../../components/Toaster'
import { commonCondition } from '../../lib/utils'
import { Customer } from '../../redux/GlobalSearch/types'

export const useGlobalSearch = (
  searchString: string
): {
  data: Customer[] | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['search-results'],
    async () => {
      if (searchString.length === 0) {
        return []
      }
      const endpoint = commonCondition
        ? `/webapp_dashboard_all_devices?offset=0&or=(email.ilike.*${encodeURIComponent(
            searchString
          )}*,gw_uid.ilike.*${searchString}*,wan_mac.ilike.*${searchString}*,title.ilike.*${searchString}*,dev_serial.ilike.*${searchString}*)`
        : `/webapp_dashboard_all_devices?offset=0&or=(email.ilike.*${encodeURIComponent(
            searchString
          )}*,gw_uid.ilike.*${searchString}*,wan_mac.ilike.*${searchString}*,title.ilike.*${searchString}*, ott_serial.ilike.*${searchString}*,dev_serial.ilike.*${searchString}*)`

      try {
        const response = await xcpemSec.get<Customer[]>(endpoint)
        return response.data
      } catch (error: any) {
        notifyError(`Search: ${error?.response?.data?.message || error?.message}`)
      }
    },
    { keepPreviousData: true, staleTime: Infinity }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
