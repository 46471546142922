import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const commonCondition = process.env.REACT_APP_REALM !== 'Hitron'
// ||
// process.env.REACT_APP_REALM === 'Customer' ||
// process.env.REACT_APP_REALM === 'earthlink' ||
// process.env.REACT_APP_REALM === 'entpnt' ||
// process.env.REACT_APP_REALM === 'Scalingtests'

export const ELEPcommon = process.env.REACT_APP_REALM === 'earthlink' || process.env.REACT_APP_REALM === 'entpnt'

export const customerScalling =
  process.env.REACT_APP_REALM === 'Customer' || process.env.REACT_APP_REALM === 'Scalingtests'

export const customerELEP = ELEPcommon || customerScalling

export const StagingScallingCustomer = process.env.REACT_APP_REALM === 'Hitron-staging' || customerScalling

export const hitronStaging = process.env.REACT_APP_REALM === 'Hitron-staging'
export const earthlink = process.env.REACT_APP_REALM === 'earthlink'

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'CREATED':
    case 'SEGMENT_CREATED':
      return '#3498db'
    case 'IN_PROGRESS':
    case 'STARTED':
    case 'SEGMENT_STARTED':
      return '#f39c12'
    case 'COMPLETED':
    case 'SEGMENT_COMPLETED':
    case 'SUCCESS':
    case 'online':
      return '#2ecc71'
    case 'FAILED':
    case 'FAILURE':
    case 'TIMEOUT':
    case 'offline':
    case 'STOPPED':
    case 'SEGMENT_STOPPED':
      return '#e74c3c'
    case 'PENDING':
    case 'WAIT_FOR_AUTO_START':
      return '#95a5a6'
    case 'SCHEDULED':
      return '#8e44ad'
    default:
      return 'black'
  }
}

export const hitronCustomerEntpntColor =
  process.env.REACT_APP_COLOR === 'hitron' ||
  process.env.REACT_APP_COLOR === 'customer' ||
  process.env.REACT_APP_COLOR === 'entpnt'
