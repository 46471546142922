import { Box, CardContent, Paper, Skeleton, styled, Typography } from '@mui/material'
import React from 'react'

interface CommonDashboardCardProps {
  count: number
  title: string | React.ReactNode
  countColor: string
  testId?: string
  className?: string
  isLoading?: boolean
  icon: React.ReactNode
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027'
  }),
  borderRadius: '1.5rem'
}))

const CommonDashboardCard = ({
  count,
  title,
  icon,
  countColor,
  isLoading,
  testId,
  className
}: CommonDashboardCardProps) => {
  return (
    <React.Fragment>
      <Item>
        <CardContent style={{ paddingBottom: 16 }} className={className}>
          <Typography variant='h3' color={countColor} height={'100px'} mt={'0.6rem'} data-testid={testId}>
            {isLoading && title !== 'Reports' ? (
              <Skeleton variant='rounded' width={'150px'} height={'50px'} sx={{ mt: '0.6rem' }} />
            ) : (
              count
            )}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography color='#000' sx={{ mt: 1, fontWeight: 500, fontSize: '1.3rem', letterSpacing: '0.025em' }}>
              {title}
            </Typography>
            {icon}
          </Box>
        </CardContent>
      </Item>
    </React.Fragment>
  )
}

export default CommonDashboardCard
