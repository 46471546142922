import { Modal } from 'antd'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSessionContex } from '../SessionContex'
import { getColorCode } from '../utils/helper'
// import { useEffect, useState } from 'react'
// import { useRefreshToken } from '../hook/Auth/useLogin'

const SessionTimeOutModal = () => {
  const {
    setOpenModal
    //  openModal
  } = useSessionContex()
  // const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    setOpenModal(false)
    localStorage.clear()
    localStorage.clear()
    navigate('/login')
  }

  // useEffect(() => {
  //   if (openModal) {
  //     const newToken: any = localStorage.getItem('refresh_token')
  //     localStorage.setItem('access_token', newToken)
  //     localStorage.setItem('refresh_token', '')
  //   }
  // }, [openModal])

  return (
    <Modal open={true} footer={null} closable={false} width={500} wrapClassName={'token_expired'} centered={true}>
      <div className='token_expired_body_wrapper device-tab'>
        <div className='text-lg font-bold text-start d-flex align-items-center border-bottom mb-3 pb-3'>
          Session has Expired
        </div>

        <div className='mb-2 row-gap-1 text-center'>
          <div className='mt-1 text-base'>Please login again to continue</div>

          <div className='mt-3'>
            <Button
              variant='contained'
              size='medium'
              sx={{
                marginRight: '10px',
                background: getColorCode('redToBlue'),
                '&:hover': {
                  backgroundColor: getColorCode('redToBlue')
                }
              }}
              onClick={handleSubmit}
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SessionTimeOutModal
