/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import Loader from '../../../components/Loader'
import { useSpeedtest } from '../../../hook/useSpeedtest'
import { commonCondition } from '../../../lib/utils'
import { useSessionContex } from '../../../SessionContex'
import { TransformedSpeedDataFunction } from '../../../utils/lib/utils'
import DocumentDownloadButton from './DocumentDownloadButton'
import DownloadSpeedChart from './download-speed-chart'
import SpeedTestHistoryChart from './speed-test-history-chart'
import UploadSpeedChart from './upload-speed-chart'

interface Props {
  gwuid: string
  dataDevice: any
  setProcessingSpeedtestCmd?: any
  processingSpeedtestCmd?: any
  setActiveId?: any
  gatewayInfo?: any
}

const SpeedTestCharts: FC<Props> = ({
  gwuid,
  setProcessingSpeedtestCmd,
  processingSpeedtestCmd,
  dataDevice,
  setActiveId,
  gatewayInfo
}) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const [dataFilter, setDataFilter] = useState<'day' | 'isoWeek'>('day')
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const [speedTestData, setSpeedTestData] = useState<any>([])

  const [isPageLoader, setIsPageLoader] = useState(true)

  const defaultFilter =
    dataFilter === 'day'
      ? gatewayInfo && !gatewayInfo?.is_online
        ? commonCondition
          ? dayjs.utc(dayjs.tz(dayjs(gatewayInfo?.last_seen).startOf('day'), timeZone)).toISOString()
          : dayjs
              .utc(dayjs.tz(dayjs(gatewayInfo?.last_seen).startOf('day'), timeZone))
              .format('YYYYMMDDTHH:mm:ss.SSSSSS')
        : commonCondition
        ? dayjs.utc(dayjs.tz(dayjs().startOf('day'), timeZone)).toISOString()
        : dayjs.utc(dayjs.tz(dayjs().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss.SSSSSS')
      : gatewayInfo && !gatewayInfo?.is_online
      ? commonCondition
        ? dayjs.utc(dayjs.tz(dayjs(gatewayInfo?.last_seen), timeZone).subtract(168, 'hours')).toISOString()
        : dayjs
            .utc(dayjs.tz(dayjs(gatewayInfo?.last_seen), timeZone).subtract(168, 'hours'))
            .format('YYYYMMDDTHH:mm:ss.SSSSSS')
      : commonCondition
      ? dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(168, 'hours')).toISOString()
      : dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(168, 'hours')).format('YYYYMMDDTHH:mm:ss.SSSSSS')

  const { data, isFetching, refetch } = useSpeedtest(gwuid, defaultFilter)

  useEffect(() => {
    if (!isFetching && data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data, isFetching])

  useEffect(() => {
    gwuid && gwuid !== '' && refetch()
    setTimeout(() => {
      setIsPageLoader(false)
      setProcessingSpeedtestCmd(false)
    }, 1500)
  }, [dataFilter])

  useEffect(() => {
    if (!isPageLoader) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isPageLoader])

  useEffect(() => {
    if (processingSpeedtestCmd && gwuid && gwuid !== '') {
      setIsPageLoader(true)
      gwuid && gwuid !== '' && refetch()
      setTimeout(() => {
        setIsPageLoader(false)
        setProcessingSpeedtestCmd(false)
      }, 1500)
    }
  }, [gwuid, processingSpeedtestCmd])

  const handleDataFilter = (filter: 'day' | 'isoWeek') => {
    if (dataFilter !== filter) {
      setDataFilter(filter)
      setIsPageLoader(true)
      // setActiveId('')
    }
  }

  useEffect(() => {
    if (data !== null && data?.length > 0) {
      // replace the new data to the old data because the flow does not change and perameter not changed
      const transformedData = TransformedSpeedDataFunction(data)

      // Update state
      setSpeedTestData(commonCondition ? transformedData : data)
    } else {
      setSpeedTestData(data)
    }
  }, [data])

  return (
    <div className='flex flex-col'>
      {isPageLoader ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {dataDevice && dataDevice?.length > 0 && (
            <div className='w-full d-flex py-2 justify-end items-center'>
              <span
                onClick={() => handleDataFilter('day')}
                className={
                  dataFilter === 'day'
                    ? `${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'hitron-active-filter'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'customer-active-filter'
                          : process.env.REACT_APP_COLOR === 'entpnt'
                          ? 'entpnt-active-filter'
                          : 'active-filter'
                      } cursor-pointer`
                    : `inactive-filter cursor-pointer`
                }
                data-testid='testid_speed_today'
              >
                Today
              </span>
              <span
                onClick={() => handleDataFilter('isoWeek')}
                className={
                  dataFilter === 'isoWeek'
                    ? `${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'hitron-active-filter'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'customer-active-filter'
                          : process.env.REACT_APP_COLOR === 'entpnt'
                          ? 'entpnt-active-filter'
                          : 'active-filter'
                      } cursor-pointer`
                    : 'inactive-filter cursor-pointer'
                }
                data-testid='testid_speed_last_7_day'
              >
                Last 7 Days
              </span>
              {process.env.REACT_APP_MODE !== 'hitron' && <DocumentDownloadButton speedTestData={speedTestData} />}
            </div>
          )}
          {(speedTestData?.length === 0 || speedTestData === null) && (
            <div className='text-center text-[#6B7A9F] font-sf-regular text-lg animate-pulse py-5'>
              There are no speed test results to display. Please run the test to see the latest results
            </div>
          )}

          {speedTestData?.length > 0 &&
            process.env.REACT_APP_REALM !== 'Hitron' &&
            process.env.REACT_APP_REALM !== 'entpnt' && (
              <div className='text-center text-[#6B7A9F] font-sf-regular text-lg animate-pulse py-2'>
                Please note that current speed test results show the Internet provider's speed without throttling.
                Internet client speeds may be lower.
              </div>
            )}

          {speedTestData !== undefined && speedTestData !== null && speedTestData.length > 0 && (
            <DownloadSpeedChart
              data={speedTestData
                ?.filter((item: any) => !item?.speedtest?.cmd)
                .map((item: any) => {
                  if (item.speedtest.params && item.speedtest.result === 'OK') {
                    return item.speedtest.params
                  } else {
                    return item?.speedtest
                  }
                })}
              categories={speedTestData
                ?.filter((item: any) => !item?.speedtest?.cmd)
                .map((item: any) => {
                  return item.timestamp
                })
                .reverse()}
            />
          )}
          {speedTestData !== undefined && speedTestData !== null && speedTestData.length > 0 && (
            <UploadSpeedChart
              data={speedTestData
                ?.filter((item: any) => !item?.speedtest?.cmd)
                ?.map((item: any) => {
                  if (item?.speedtest?.params) {
                    item.speedtest.params.timestamp = item?.timestamp
                    return item?.speedtest?.params
                  } else {
                    return item?.speedtest
                  }
                })}
              categories={speedTestData
                ?.filter((item: any) => !item?.speedtest?.cmd)
                .map((item: any) => {
                  return item?.timestamp
                })
                .reverse()}
            />
          )}
          {speedTestData?.length > 0 && speedTestData !== undefined && speedTestData !== null && (
            <SpeedTestHistoryChart
              data={speedTestData
                ?.filter((item: any) => !item?.speedtest?.cmd)
                ?.map((item: any) => {
                  if (item?.speedtest?.params) {
                    // item.speedtest.timestamp = item.timestamp
                    return item?.speedtest?.params
                  } else {
                    item.speedtest.timestamp = item?.timestamp
                    return item?.speedtest
                  }
                })
                .reverse()}
              dataFilter={dataFilter}
            />
          )}
        </>
      )}
    </div>
  )
}

export default SpeedTestCharts
