import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { v4prifix } from '../app-config'

export const useDashboardCellularSummary = (
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['dashboard-cellular-summary'],
    async () => {
      const endpoint = `${v4prifix}/cellular/grp_usage_summary?timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage Summary: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularSummary = (
  white_label_id: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-summary'],
    async () => {
      const params = new URLSearchParams()

      if (white_label_id) params.append('white_label_id', `${white_label_id}`)
      if (timestamp !== undefined) params.append('timestamp_from', timestamp)

      const endpoint = `${v4prifix}/cellular/grp_usage_summary?${params.toString()}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data ?? null
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          // notifyError(`Cellular Summary: ${error?.response?.data?.message || error?.message}`)
          return null
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularOverviewUsage = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-Overview-usage'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `${v4prifix}/cellular/gw_usage_summary?gw_uuid=${gwuid}&timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Overview Usage Summary: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularUsage = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `${v4prifix}/cellular/gw_usage_summary?gw_uuid=${gwuid}&timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage Summary: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularUsageSummary = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage-summary'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `${v4prifix}/cellular/gw_usage_durations?gw_uuid=${gwuid}&timestamp_from=${timestamp}`
      // const endpoint = `/rpc/gateway_cellular_usage_summary?_gw_uid=${gwuid}&_timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage Summary: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularUsageData = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage-data'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `${v4prifix}/cellular/gw_usage_raw_data?gw_uuid=${gwuid}&timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage Data: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularUsageGraph = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage-graph'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      // const endpoint = `/rpc/gateway_cellular_usage_graph?_gw_uid=${gwuid}&_timestamp_from=${timestamp}`
      // const endpoint = `${v4prifix}/cellular/gw_usage_graph?gw_uuid=${gwuid}&timestamp_from=${timestamp}`
      const endpoint = `${v4prifix}/cellular/gw_usage_raw_data?gw_uuid=${gwuid}&timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage graph: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularGWUsageSummary = (
  white_label_id: string,
  timestamp: string,
  limit?: number
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-GWUsageSummary'],
    async () => {
      const params = new URLSearchParams()

      if (white_label_id) params.append('white_label_id', `${white_label_id}`)
      if (timestamp !== undefined) params.append('timestamp_from', timestamp)
      if (limit !== undefined) params.append('limit', limit.toString())

      const endpoint = `${v4prifix}/cellular/grp_gw_usage_summary?${params.toString()}`

      try {
        const response = await xcpemSec.get(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          // notifyError(`Cellular Usage Summary: ${error?.response?.data?.message || error?.message}`)
          return null
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularGWUsageStats = (
  white_label_id: string,
  timestamp: string,
  interval?: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-GWUsageStats'],
    async () => {
      const params = new URLSearchParams()

      if (white_label_id) params.append('white_label_id', `${white_label_id}`)
      if (timestamp !== undefined) params.append('timestamp_from', timestamp)
      if (interval !== undefined) params.append('interval', interval.toString())

      const endpoint = `${v4prifix}/cellular/grp_usage_stats?${params.toString()}`

      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          // notifyError(`Cellular Usage Stats: ${error?.response?.data?.message || error?.message}`)
          return null
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
