import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment/moment'
import { FC } from 'react'
import { commonCondition } from '../../../lib/utils'
import { getColorCode } from '../../../utils/helper'

interface Props {
  data: any
  dataFilter: any
}
const SpeedTestHistoryChart: FC<Props> = ({ data, dataFilter }) => {
  const chartCategories: string[] = data?.map((item: any) => {
    return item.timestamp
  })

  const series1Data = data
    ?.filter((item: any) => item.result !== 'ERROR')
    ?.map((item: any) => {
      return Math.ceil(item.max_download_speed)
    })
  const series2Data = data
    ?.filter((item: any) => item.result !== 'ERROR')
    ?.map((item: any) => {
      return Math.ceil(item.max_upload_speed)
    })

  const options = {
    chart: {
      type: series1Data && series2Data && series1Data?.length > 2 && series2Data?.length > 2 ? 'line' : 'column',
      backgroundColor: '#ffffff'
      // barWidth: 10
    },
    title: {
      text: undefined
    },
    xAxis: {
      categories: chartCategories,
      gridLineColor: 'transparent',
      lineWidth: 2,
      // labels: {
      //   enabled: false
      // }
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          const momentValue = moment(commonCondition ? this.value : this.value + 'Z').local()
          return momentValue.format('hh:mm A')
        }
      }
    },
    yAxis: {
      title: {
        text: undefined
      },
      gridLineColor: 'transparent',
      lineWidth: 2,
      startOnTick: true
    },
    plotOptions: {
      // bar: {
      //   dataLabels: {
      //     enabled: false
      //   },
      //   width: 1,
      // },
      bar: {
        groupPadding: 0,
        pointPadding: 0,
        dataLabels: {
          enabled: true
        }
      },
      series: {
        pointWidth: 100,
        pointPadding: 0,
        pointMargin: 0
      }
    },
    series: [
      {
        name: 'Download',
        data: series1Data,
        color: getColorCode('chartDarkGrayDarkPink')
      },
      {
        name: 'Upload',
        data: series2Data,
        color: getColorCode('chartRedBlue')
      }
    ],
    legend: {
      align: 'center',
      // verticalAlign: 'top',
      layout: 'horizontal',
      x: 0,
      y: 0,
      itemMarginTop: 10,
      itemMarginBottom: 10
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        //@ts-ignore
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          this.series.name +
          ': </b></span>' +
          '<span>' +
          this.y +
          ' Mbps</span><br/>' +
          '<span><b>when: </b>' +
          moment(commonCondition ? this.x : this.x + 'Z')
            .local()
            .fromNow() +
          `( ${moment(commonCondition ? this.x : this.x + 'Z')
            .local()
            .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)})` +
          '</span><br/>' +
          '</div>'
        )
      }
    }
  }

  return (
    <div className='bg-layout-neutralBg px-3'>
      <h1 className='text-[16px] font-sf-semibold text-layout-textOnSurface mb-[18px] pt-6 '>History</h1>

      <HighchartsReact highcharts={Highcharts} options={options} height={200} />
    </div>
  )
}

export default SpeedTestHistoryChart
