import { DocumentDownload } from 'iconsax-react'
import moment from 'moment'
import React from 'react'
import { commonCondition } from '../../../lib/utils'

interface DocumentDownloadButtonProps {
  speedTestData?: any
}

const DocumentDownloadButton = ({ speedTestData }: DocumentDownloadButtonProps) => {
  const headers = ['average_download_speed', 'avg_upload_speed', 'ping', 'server_name', 'timestamp']
  const displayHeaders = ['Download Speed (mbps)', 'Upload Speed (mbps)', 'Ping', 'Server Name', 'Timestamp']

  return (
    <React.Fragment>
      <span
        onClick={() => {
          if (speedTestData && speedTestData?.length !== 0) {
            // Create header row
            let csv = displayHeaders.join(',') + '\n'
            // Create rows
            for (let item of speedTestData) {
              const row = headers
                .map(header =>
                  header === 'timestamp'
                    ? moment(commonCondition ? item?.speedtest?.params[header] : item?.speedtest?.params[header] + 'Z')
                        ?.local()
                        ?.format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                    : header === 'server_name'
                    ? item?.speedtest?.params[header]?.replace(',', ' ')
                    : item?.speedtest?.params[header]
                )
                .join(',')
              csv += row + '\n'
            }
            let blob = new Blob([csv], { type: 'text/csv' })
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = url
            a.download = 'speedTest.csv'
            document.body.appendChild(a)
            a.click()
          }
        }}
        title='Export'
        style={{
          borderRadius: '10px',
          padding: '8px',
          background: speedTestData?.length === 0 || !speedTestData ? '#ccc' : '#fff',
          cursor: speedTestData?.length === 0 || !speedTestData ? 'default' : 'pointer',
          margin: '0 8px'
        }}
        aria-disabled={speedTestData?.length === 0 || !speedTestData}
        data-testid='testid_speed_test_export'
      >
        <DocumentDownload />
      </span>
    </React.Fragment>
  )
}

export default DocumentDownloadButton
