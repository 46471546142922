/* eslint-disable react-hooks/exhaustive-deps */
import { Snackbar, Stack, Switch, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4prifix } from '../../../app-config'
import xcpemCyber from '../../../axiosInstances/xcpemCyber'
import xcpemSec from '../../../axiosInstances/xcpemSec'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { useGatewayServices } from '../../../hook/useGatewayServices'
import { commonCondition } from '../../../lib/utils'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { useSessionContex } from '../../../SessionContex'
import { getColorCode } from '../../../utils/helper'
import { SnackBarProps } from '../../Device-table/Provisioning'
import Loader from '../../Loader'
import './styles.scss'

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const CustomerServices = () => {
  const { openModal, setOpenModal, reCall, setReCall, setServiceApi } = useSessionContex()

  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { data: gatewayInfo, isFetching: gatewayFetching, refetch: getwayRefetch }: any = useGatewayInfo(activeGw_Uid) //User device data

  const { data, isLoading, refetch, isFetching } = useGatewayServices(activeGw_Uid)
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: '',
    type: 'Closed'
  })

  const [checked, setChecked] = React.useState({
    wifi: true,
    service: true
  })

  const handleWiFiChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedState = event.target.checked // Store the new checked state
    setChecked(prevState => ({ ...prevState, wifi: newCheckedState })) // Update immediately

    const host = `https://${process.env.REACT_APP_IOTCORE}`
    const baseUrl = `${host}`

    try {
      const response = await xcpemSec.post(`${baseUrl}${v4prifix}/mesh/control`, {
        gw_uuid: activeGw_Uid,
        mesh_enabled: newCheckedState
      })
      setSnackBarInfo({
        isOpen: true,
        message: newCheckedState ? 'Services enabled successfully' : 'Services disabled successfully',
        type: 'Closed'
      })
      if (response.status === 200) {
        refetch()
      }
    } catch (error: any) {
      console.error('Error in API call:', error)
    }
  }

  const handleServicesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedState = event.target.checked // Store the new checked state
    setChecked(prevState => ({ ...prevState, service: newCheckedState })) // Update immediately

    try {
      const response = await xcpemCyber.post(`/api/v4/ui/cyber/control`, {
        gw_uuid: activeGw_Uid,
        cs_enabled: newCheckedState,
        pc_enabled: newCheckedState
      })

      setSnackBarInfo({
        isOpen: true,
        message: newCheckedState ? 'Services enabled successfully' : 'Services disabled successfully',
        type: 'Closed'
      })

      if (response.status === 200) {
        refetch()
      }
    } catch (error: any) {
      console.error('Error in API call:', error)
    }
  }

  useEffect(() => {
    refetch()
    getwayRefetch()
    localStorage.removeItem('isPCverified')
    setServiceApi(false)
  }, [activeGw_Uid])

  useEffect(() => {
    if (reCall) {
      refetch()
      getwayRefetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!(isFetching || isLoading || gatewayFetching)) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching, isLoading, gatewayFetching])

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
    if (data) {
      setChecked({
        wifi: data?.srv_mesh_enabled,
        service: data?.srv_cs_enabled || data?.srv_pc_enabled
      })
    }
  }, [data])

  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [gatewayInfo])

  const navigate = useNavigate()
  const activeClass: any = `${
    process.env.REACT_APP_COLOR === 'hitron' ||
    process.env.REACT_APP_COLOR === 'customer' ||
    process.env.REACT_APP_COLOR === 'entpnt'
      ? 'hitronbgActive'
      : 'bgActive'
  }`
  const inActiveClass: any = `${
    process.env.REACT_APP_COLOR === 'hitron' ||
    process.env.REACT_APP_COLOR === 'customer' ||
    process.env.REACT_APP_COLOR === 'entpnt'
      ? 'hitronbgInActive'
      : 'bgInActive'
  }`

  return (
    <div className='service-main-Wrapper'>
      {isFetching || isLoading || gatewayFetching ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <>
          <div className='font-sf-regular service-inner-Wrapper d-none'>
            <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
              <span className='font-sf-medium serviceTitle'>Router Platform</span>
              {isLoading ? (
                <span className='service-tag text-white text-[14px] rounded-full px-4 py-0.5 font-sf-light'>
                  Loading...
                </span>
              ) : (
                <span
                  className={`${activeClass} service-tag text-white text-[14px] rounded-full px-4 py-0.5 font-sf-light`}
                >
                  Active
                </span>
              )}
            </div>
            <div className='mx-auto w-full mt-3 service-inner-block service-router-btn'>
              {/* <a
            href={`https://account.xunison.com/customer/router-platform?uid=${activeGw_Uid}`}
            rel='noreferrer'
            target='_blank'
            className='service-button'
          >
            Visit Router Platform
          </a> */}
            </div>
          </div>
          {gatewayInfo?.dev_mesh_role === 'CAP' ? (
            <div className='flex gap-3'>
              <div className='font-sf-regular service-inner-Wrapper'>
                <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
                  <span className='font-sf-medium serviceTitle'>Parental Control</span>
                  <span
                    className={`service-tag text-white text-[14px] rounded-full ${
                      data?.srv_pc_enabled ? activeClass : inActiveClass
                    } px-4 py-0.5 font-sf-light`}
                  >
                    {data?.srv_pc_enabled ? 'Active' : 'Inactive'}
                  </span>
                </div>

                <div className='mx-auto w-full mt-3 service-inner-block'>
                  <button
                    type='button'
                    onClick={() =>
                      navigate(`/dashboard/${activeGw_Uid}?email=${gatewayInfo?.email}`, {
                        state: 'service'
                      })
                    }
                    className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-pointer service-button'
                    style={{
                      background: `${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? '#004c97'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? '#ff974c'
                          : process.env.REACT_APP_COLOR === 'entpnt'
                          ? '#487a7b'
                          : '#6581fa'
                      }`
                    }}
                    data-testid='testid_parental_control'
                  >
                    Visit Parental Control
                  </button>
                </div>
              </div>

              <div className='font-sf-regular service-inner-Wrapper'>
                <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
                  <span className='font-sf-medium serviceTitle'>Cyber Security</span>
                  <span
                    className={`service-tag text-white text-[14px] rounded-full ${
                      data?.srv_cs_enabled ? activeClass : inActiveClass
                    } px-4 py-0.5 font-sf-light`}
                  >
                    {data?.srv_cs_enabled ? 'Active' : 'Inactive'}
                  </span>
                </div>
                <div className='mx-auto w-full mt-3 service-inner-block'>
                  <button
                    type='button'
                    onClick={() =>
                      navigate(`/dashboard/${activeGw_Uid}/${gatewayInfo?.email}`, {
                        state: 'service'
                      })
                    }
                    className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-pointer service-button'
                    style={{
                      background: `${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? '#004c97'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? '#ff974c'
                          : process.env.REACT_APP_COLOR === 'entpnt'
                          ? '#487a7b'
                          : '#6581fa'
                      }`
                    }}
                    data-testid='testid_cyber_security'
                  >
                    Visit Cyber Security
                  </button>
                </div>
              </div>

              {/* <div className='font-sf-regular service-inner-Wrapper'>
                <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
                  <span className='font-sf-medium serviceTitle'>Smart Home</span>
                  <span
                    className={`service-tag text-white text-[14px] rounded-full bgComming px-4 py-0.5 font-sf-light`}
                  >
                    Coming Soon
                  </span>
                </div>
                <div className='mx-auto w-full mt-3 service-inner-block'>
                  <button
                    type='button'
                    // onClick={() => {
                    //   localStorage.setItem('isPCverified', 'true')
                    //   navigate(`/smart-home/${activeGw_Uid}?email=${email}`)
                    // }}
                    className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-not-allowed service-button notAllow'
                  >

                    Visit Smart Home Platform
                  </button>
                </div>
              </div> */}

              {/* <div className='font-sf-regular service-inner-Wrapper'>
            <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
              <span className='font-sf-medium serviceTitle'>Elderly Care</span>
              <span className={`service-tag text-white text-[14px] rounded-full bgComming bg-amber-300 px-4 py-0.5 font-sf-light`}>
                Coming Soon
              </span>
            </div>
            <div className='mx-auto w-full mt-3 service-inner-block'>
              <button
                  type='button'
                  // onClick={() => {
                  //   localStorage.setItem('isPCverified', 'true')
                  //   navigate(`/smart-home/${activeGw_Uid}?email=${email}`)
                  // }}
                  className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-not-allowed service-button notAllow'
              >
                {/*                         <button type='button' disabled={true}
                 * /}
                Visit Elderly Care
              </button>
            </div>
          </div>
          <div className='font-sf-regular service-inner-Wrapper'>
            <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block' style={{gap: "0"}}>
              <span className='font-sf-medium serviceTitle'>Energy Management</span>
              <span className={`service-tag text-white text-[14px] rounded-full bgComming bg-amber-300 px-4 py-0.5 font-sf-light `}>
                Coming Soon
              </span>
            </div>
            <div className='mx-auto w-full mt-3 service-inner-block'>
              <button
                  type='button'
                  // onClick={() => {
                  //   localStorage.setItem('isPCverified', 'true')
                  //   navigate(`/smart-home/${activeGw_Uid}?email=${email}`)
                  // }}
                  className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-not-allowed service-button notAllow'
              >
                {/*                         <button type='button' disabled={true}
                 * /}
                Visit Energy Management
              </button>
            </div>
          </div> */}
            </div>
          ) : (
            <></>
            // <div className='font-sf-regular service-inner-Wrapper'>
            //   <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
            //     <span className='font-sf-medium serviceTitle'>Services are not available for Satellite Devices</span>

            //     <span
            //       className={`service-tag text-white text-[14px] rounded-full bg-alerts-critical ${inActiveClass} px-2 py-0.5 font-sf-light`}
            //     >
            //       -
            //     </span>
            //   </div>
            //   <div className='mx-auto w-full mt-3 service-inner-block'>
            //     <button
            //       type='button'
            //       className=' text-white cursor-not-allowed w-full font-sf-light px-6 text-[14px] py-2 rounded-md service-button'
            //       style={{
            //        style={{
            // background: `${
            //   process.env.REACT_APP_COLOR === 'hitron'
            //     ? '#004c97'
            //     : process.env.REACT_APP_COLOR === 'customer'
            //     ? '#ff974c'
            //     : '#6581fa'
            // }`
            //       }}
            //     >
            //       Services not available on Satellite Device
            //     </button>
            //   </div>
            // </div>
          )}
          {commonCondition && (
            <Stack className='mt-16 service-inner-Wrapper' direction='column' spacing={4} style={{ width: '400px' }}>
              <div className='flex gap-4 justify-between items-center'>
                <h3 className=''>Managed WiFi</h3>
                <Stack
                  className='shadow-sm'
                  direction='row'
                  spacing={1}
                  sx={{ alignItems: 'center', border: '0.5px solid #ededed', px: '1rem' }}
                >
                  <Typography>Off</Typography>
                  <Switch
                    checked={checked.wifi}
                    onChange={handleWiFiChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>On</Typography>
                </Stack>
              </div>
              <div className='flex gap-4 items-center justify-between'>
                <h3>Services (PC & CS)</h3>
                <Stack
                  className='shadow-sm'
                  direction='row'
                  spacing={1}
                  sx={{ alignItems: 'center', border: '0.5px solid #ededed', px: '1rem' }}
                >
                  <Typography>Off</Typography>
                  <Switch
                    checked={checked.service}
                    onChange={handleServicesChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>On</Typography>
                </Stack>
              </div>
            </Stack>
          )}
        </>
      )}
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert severity='success' sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}>
            {snackBarInfo.message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  )
}
export default CustomerServices
