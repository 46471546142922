import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import moment from 'moment'
import React from 'react'
import { FirmwareUpdateList } from '../../../components/GroupsTable/types'
import Loader from '../../../components/Loader'
import { getStatusColor } from '../../../lib/utils'

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold'
})

interface UserHistoryProps {
  userHistoryList?: FirmwareUpdateList[]
  isFetching?: boolean
  isLoading?: boolean
}

const UserHistory = ({ userHistoryList, isLoading = false, isFetching = false }: UserHistoryProps) => {
  return (
    <React.Fragment>
      <Box sx={{ mt: 5 }}>
        <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>User History</h2>
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: '100%',
            maxHeight: '375px', // Adjust the height as needed
            overflowY: 'auto',
            overflowX: 'auto'
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>JOB ID</StyledTableCell>
                <StyledTableCell>START TIME</StyledTableCell>
                <StyledTableCell>END TIME</StyledTableCell>
                <StyledTableCell>INITIAL FIRMWARE</StyledTableCell>
                <StyledTableCell>CURRENT FIRMWARE</StyledTableCell>
                <StyledTableCell>STATUS</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching || isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{ textAlign: 'center' }}
                    className='flex justify-center items-center w-full'
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : !userHistoryList || userHistoryList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                    No User History Found!!!
                  </TableCell>
                </TableRow>
              ) : (
                userHistoryList &&
                userHistoryList?.map((res, index: number) => {
                  const segementStartTime = res.upgrade_started_at
                    ? moment.utc(res.upgrade_started_at).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                    : '-'
                  const segementEndTime = res.upgrade_finished_at
                    ? moment.utc(res.upgrade_finished_at).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                    : '-'

                  return (
                    <TableRow key={index}>
                      <TableCell>#{res.job_id}</TableCell>
                      <TableCell>{segementStartTime}</TableCell>
                      <TableCell>{segementEndTime}</TableCell>
                      <TableCell>{res.initial_fw_version}</TableCell>
                      <TableCell>{res.target_fw_version}</TableCell>
                      <TableCell style={{ color: getStatusColor(res.status), fontWeight: 700 }}>{res.status}</TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  )
}

export default UserHistory
