import React from 'react'

const DevicesLogo = ({ color = 'black' }: { color?: string }) => {
  return (
    <React.Fragment>
      <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M56.2099 36.0707C56.2718 36.0707 56.2718 36.0707 56.3337 36.0707C57.1383 36.1326 57.6954 36.8134 57.6335 37.5562C56.8289 49.3779 45.688 58.6 32.3189 58.6C18.9499 58.6 7.80901 49.3779 7.00439 37.618C6.9425 36.8134 7.56144 36.1945 8.30416 36.1326C9.10878 36.0707 9.72772 36.6896 9.78961 37.4324C10.5323 47.7686 20.4353 55.8148 32.3189 55.8148C44.2644 55.8148 54.1674 47.7067 54.8483 37.3705C54.9102 36.6278 55.5291 36.0707 56.2099 36.0707Z'
          fill={color}
        />
        <path
          d='M8.42825 27.5293C8.36635 27.5293 8.36635 27.5293 8.30446 27.5293C7.49984 27.4674 6.9428 26.7866 7.00469 26.0439C7.80931 14.2222 18.9502 5 32.3192 5C45.6883 5 56.8292 14.2222 57.6338 25.982C57.6957 26.7866 57.0767 27.4055 56.334 27.4674C55.5294 27.5293 54.9105 26.9104 54.8486 26.1677C54.1058 15.8314 44.2028 7.78522 32.3192 7.78522C20.3737 7.78522 10.4707 15.8933 9.78991 26.2296C9.72802 26.9723 9.10908 27.5293 8.42825 27.5293Z'
          fill={color}
        />
        <path
          d='M32.3193 46.2236C24.5207 46.2236 18.2075 39.9104 18.2075 32.1118C18.2075 24.3132 24.5207 18 32.3193 18C40.1179 18 46.4311 24.3132 46.4311 32.1118C46.4311 39.9104 40.1179 46.2236 32.3193 46.2236ZM32.3193 20.8471C26.1299 20.8471 21.0546 25.9224 21.0546 32.1118C21.0546 38.3012 26.1299 43.3764 32.3193 43.3764C38.5087 43.3764 43.584 38.3012 43.584 32.1118C43.584 25.9224 38.5087 20.8471 32.3193 20.8471Z'
          fill={color}
        />
      </svg>
    </React.Fragment>
  )
}

export default DevicesLogo
