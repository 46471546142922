import { wifiBandWidthProps } from '.'
import { HardwareCategory, LeftItem, SignalArray } from './type'

export const leftArray: LeftItem[] = [
  {
    id: 1,
    name: '5G/4G Manager',
    title: 'Overview',
    description: 'Overview of 5G/4G device'
  },
  {
    id: 2,
    name: 'WiFi Channel Manager',
    title: 'WiFi Channel Manager',
    description: 'Here you can check 2.4 GHz & 5 GHz settings'
  },
  {
    id: 3,
    name: 'LAN Settings',
    title: 'LAN Settings',
    description: 'Here you can configure LAN settings'
  },
  {
    id: 4,
    name: process.env.REACT_APP_REALM !== 'Hitron' ? 'WiFi Basic Settings' : 'WiFi Basic Setting',
    title: process.env.REACT_APP_REALM !== 'Hitron' ? 'WiFi Basic Settings' : 'WiFi Basic Setting',
    description: `Here you can change ${
      process.env.REACT_APP_REALM !== 'Hitron' ? '2.4 GHz & 5 GHz' : '2.4G & 5G'
    } settings`
  },
  {
    id: 5,
    name: 'Speed Settings',
    title: 'Speed Settings',
    description: 'Here you can change Speed settings'
  }
]

export const hardwareArray1: HardwareCategory[] = [
  {
    title: 'Hardware',
    data: [
      {
        name: 'Manufacturer',
        value: 'N/A'
      },
      {
        name: 'Model',
        value: 'N/A'
      },
      {
        name: 'Firmware Revision',
        value: 'N/A'
      }
    ]
  },
  {
    title: '3GPP',
    data: [
      {
        name: 'IMEI',
        value: 'N/A',
        key: 'imei'
      },
      {
        name: 'Operator ID',
        value: 'N/A',
        key: 'oper',
        main: 'network_info'
      },
      {
        name: 'Operator Name',
        value: 'N/A',
        main: 'mobile_operator',
        key: 'oper'
      },
      {
        name: 'Band',
        value: 'N/A',
        main: 'network_info',
        key: 'band'
      },
      {
        name: 'Channel',
        value: 'N/A',
        main: 'network_info',
        key: 'channel'
      },
      {
        name: 'Act',
        value: 'N/A',
        main: 'network_info',
        key: 'Act'
      },
      {
        name: 'Type',
        value: 'N/A',
        key: 'connection_label'
      }
    ]
  },
  {
    title: 'Status',
    data: [
      {
        name: 'State',
        value: 'N/A',
        key: 'status'
      },
      {
        name: 'Sim State',
        value: 'N/A',
        key: 'sim_status'
      }
    ]
  }
]

export const signalArray1: SignalArray = [
  {
    title: 'Cellular',
    data: [
      {
        name: 'RSRP',
        value: 'N/A',
        key: 'rsrp'
      },
      {
        name: 'RSRQ',
        value: 'N/A',
        key: 'rsrq'
      },
      {
        name: 'SNR',
        value: 'N/A',
        key: 'sinr'
      },
      {
        name: 'Cell Id',
        value: 'N/A',
        key: 'cellid'
      },
      {
        name: 'EARFCN',
        value: 'N/A',
        key: 'earfcn'
      },
      {
        name: 'PCID',
        value: 'N/A',
        key: 'pcid'
      },
      {
        name: 'RSSI',
        value: 'N/A',
        key: 'rssi'
      }
    ]
  }
]

export const wifi5GBandWidth: wifiBandWidthProps[] = [
  {
    label: '20 MHz',
    value: 'HT20'
  },
  {
    label: '20/40 MHz',
    value: 'HT40'
  },
  {
    label: '20/40/80 MHz',
    value: 'HT80'
  },
  {
    label: '20/40/80/160 MHz',
    value: 'HT160'
  }
]

export const wifi2GBandWidth: wifiBandWidthProps[] = [
  {
    label: '20 MHz',
    value: 'HT20'
  },
  {
    label: '40 MHz',
    value: 'HT40'
  },
  {
    label: '20/40 Mhz',
    value: 'auto'
  }
]
