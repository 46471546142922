import { Tab, TabPanel, Tabs, TabsList, tabClasses } from '@mui/base'
import { styled } from '@mui/material'
import React from 'react'

const CustomeTabs = ({
  defaultActiveTabs = 1,
  tabsList,
  divider = null,
  snackbar = null,
  className = '',
  displayTab = true
}: any) => {
  return (
    <div className={className}>
      <Tabs defaultValue={defaultActiveTabs}>
        {displayTab && (
          <StyledTabsList>
            {tabsList.length > 0 &&
              tabsList.map((tab: any, i: number) => {
                return (
                  <StyledTab value={i + 1} key={i} data-testid={tab?.testId}>
                    {tab.label}
                  </StyledTab>
                )
              })}
          </StyledTabsList>
        )}
        {divider && divider}
        {tabsList.length > 0 &&
          tabsList.map((item: any, i: number) => {
            return (
              <StyledTabPanel value={i + 1} className='mt-3' key={i}>
                {item.tab}
              </StyledTabPanel>
            )
          })}
        {snackbar && snackbar}
      </Tabs>
    </div>
  )
}

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0070F0',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75'
}

const StyledTab = styled(Tab)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 10px;
  height: 36px;
  width: auto;
  margin-right: 11px;
  background-color: #f6f7f9;
  color: #708398;
  border: 1px SOLID #f6f7f9;

  &:hover {
    background-color: white;
    color: #212121;
    border: 1px SOLID #e2e2e2;
  }

  &:focus {
    color: #212121;
  }

  &.${tabClasses.selected} {
    background-color: ${process.env.REACT_APP_COLOR === 'hitron'
      ? '#004C97'
      : process.env.REACT_APP_COLOR === 'customer'
      ? '#ff974c'
      : process.env.REACT_APP_COLOR === 'entpnt'
      ? '#487a7b'
      : blue[600]} !important;
    color: #e8ebef !important;
  }
`

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
`

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
      min-width: 400px;
      margin-bottom: 16px;
      margin-left: 9px;
      `
)

export default CustomeTabs
