import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import moment from 'moment'
import { FirmwareUpgrade } from '../../../components/GroupsTable/types'
import Loader from '../../../components/Loader'
import { getStatusColor } from '../../../lib/utils'

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold'
})

interface BatchUpgradeJobListProps {
  upgradeJobsList: FirmwareUpgrade[]
  isFetching: boolean
  isLoading: boolean
}

const BatchUpgradeJobList = ({ upgradeJobsList, isLoading, isFetching }: BatchUpgradeJobListProps) => {
  return (
    <Box sx={{ mt: 5 }}>
      <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>Job Upgrade Information</h2>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: '100%',
          maxHeight: '375px', // Adjust the height as needed
          overflowY: 'auto',
          overflowX: 'auto'
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>GW UID</StyledTableCell>
              <StyledTableCell>SEGMENT ID</StyledTableCell>
              <StyledTableCell>CURRENT FIRMWARE VERSION</StyledTableCell>
              <StyledTableCell>TARGET FIRMWARE VERSION</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>UPGRADE START</StyledTableCell>
              <StyledTableCell>UPGRADE END</StyledTableCell>
              <StyledTableCell>UPDATE TIME</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching || isLoading ? (
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: 'center' }} className='flex justify-center items-center w-full'>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : !upgradeJobsList || upgradeJobsList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                  No Segement Data Found!!!
                </TableCell>
              </TableRow>
            ) : (
              upgradeJobsList &&
              upgradeJobsList?.map((res, index: number) => {
                const segementCreatedTime = res.updated_at
                  ? moment.utc(res.updated_at).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                  : '-'
                const segementStartTime = res.upgrade_start_time
                  ? moment.utc(res.upgrade_start_time).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                  : '-'
                const segementEndTime = res.upgrade_end_time
                  ? moment.utc(res.upgrade_end_time).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                  : '-'
                return (
                  <TableRow key={index}>
                    <TableCell>{res.gw_uid}</TableCell>
                    <TableCell>#{res.segment_id}</TableCell>
                    <TableCell>{res.current_firmware_version}</TableCell>
                    <TableCell>{res.target_firmware_version}</TableCell>
                    <TableCell style={{ color: getStatusColor(res.status), fontWeight: 700 }}>{res.status}</TableCell>
                    <TableCell>{segementStartTime}</TableCell>
                    <TableCell>{segementEndTime}</TableCell>
                    <TableCell>{segementCreatedTime}</TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default BatchUpgradeJobList
