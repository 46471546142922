import React from 'react'

const Customer = () => {
  return (
    <React.Fragment>
      <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.42825 58.6C8.36635 58.6 8.36635 58.6 8.30446 58.6C7.49984 58.5381 6.9428 57.8573 7.00469 57.1146C7.80931 45.2928 18.9502 36.0707 32.3192 36.0707C45.6883 36.0707 56.8292 45.2929 57.6338 57.0527C57.6957 57.8573 57.0767 58.4762 56.334 58.5381C55.5294 58.6 54.9105 57.9811 54.8486 57.2383C54.1058 46.9021 44.2028 38.8559 32.3192 38.8559C20.3737 38.8559 10.4707 46.964 9.78991 57.3002C9.72802 58.043 9.10908 58.6 8.42825 58.6Z'
          fill='black'
        />
        <path
          d='M32.3193 33.2236C24.5207 33.2236 18.2075 26.9104 18.2075 19.1118C18.2075 11.3132 24.5207 5 32.3193 5C40.1179 5 46.4311 11.3132 46.4311 19.1118C46.4311 26.9104 40.1179 33.2236 32.3193 33.2236ZM32.3193 7.84711C26.1299 7.84711 21.0546 12.9224 21.0546 19.1118C21.0546 25.3012 26.1299 30.3764 32.3193 30.3764C38.5087 30.3764 43.584 25.3012 43.584 19.1118C43.584 12.9224 38.5087 7.84711 32.3193 7.84711Z'
          fill='black'
        />
      </svg>
    </React.Fragment>
  )
}

export default Customer
