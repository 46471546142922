import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import moment from 'moment'
import React from 'react'
import Button from '../../../components/GroupsTable/Button'
import { Betches } from '../../../components/GroupsTable/types'
import { getStatusColor } from '../../../lib/utils'

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold'
})

interface BatchInformationProps {
  batchData: Betches
}

const BatchInformation = ({ batchData }: BatchInformationProps) => {
  const timeDate = batchData?.created_at
    ? moment.utc(batchData?.created_at).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
    : '-'
  const startTimeDate = batchData?.start_time
    ? moment.utc(batchData?.start_time).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
    : '-'

  return (
    <React.Fragment>
      <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>General Batch Information</h2>
      <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>TIME CREATED</StyledTableCell>
              <StyledTableCell>TITLE</StyledTableCell>
              <StyledTableCell>GROUP NAME</StyledTableCell>
              <StyledTableCell>FIRMWARE</StyledTableCell>
              <StyledTableCell>STATUS and TIMESTAMP</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{timeDate}</TableCell>
              <TableCell>{batchData.batch_name}</TableCell>
              <TableCell>{batchData.group_name}</TableCell>
              <TableCell>{batchData.target_firmware}</TableCell>
              <TableCell>
                <span style={{ color: getStatusColor(batchData.status), fontWeight: 'bold' }}>{batchData.status}</span>
                <Button className='mx-2 bg-[#FF6C6E] text-[#fff] px-3 py-2 rounded-xl text-[13px]' disabled>
                  Stop
                </Button>
                {startTimeDate}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default BatchInformation
