import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { v4prifix } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { commonCondition } from '../lib/utils'

export const useConnectedDevices = (
  gw_uid: string,
  from?: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['networkmap-devices'],
    async () => {
      const queryString = commonCondition
        ? `${v4prifix}/network/map?gw_uuid=${gw_uid}&direction=back${from ? `&time=${from}` : '&time='}`
        : `/rpc/webapp_wifi_network_health_map_dev_type?_gw_uid=${gw_uid}&_direction=back${
            from ? `&_time=${from}` : '&_time='
          }`
      // const queryString = `/rpc/webapp_wifi_network_health_map_dev_type?_gw_uid=${gw_uid}&_direction=back${
      //   from ? `&_time=${from}` : '&_time='
      // }`

      try {
        const response = await xcpemSec.get<any>(queryString)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Network health map: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['use-Wifi-QoE'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
