import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { apiUrl, KEYCLOAK_CONFIG } from '../app-config'
import keyClock from '../axiosInstances/keyClock'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { commonCondition } from '../lib/utils'
import { Customer } from '../models/Xcpem'

export const useAllCustomers = (offset: number = 0, limit: number = 6): {} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['customers'],
    async () => {
      const endpoint = `${apiUrl}/webapp_white_labels_list?limit=${limit}&offset=${offset}`
      try {
        const response = await xcpemSec.get<Customer[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Labels list: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGetCustomerAndCount = (): any => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['customersWithCount'],
    async () => {
      const endpoint = `${apiUrl}/webapp_gw_count_per_white_label`
      try {
        const response = await xcpemSec.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Labels list with gw count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGetOnlineCount = (): any => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['OnlineConTypeCount'],
    async () => {
      const endpoint = `${apiUrl}/rpc/online_gw_conn_types_count`
      try {
        const response = await xcpemSec.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Online gw conn types count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

const host = `https://${process.env.REACT_APP_IOTCORE}`
const baseUrl = `${host}`
const access_token = localStorage.getItem('access_token')

export const addMSO = async (payload: any) => {
  const response = await xcpemSec.post(`${baseUrl}/webapp_white_labels_list`, payload)

  return response.data
}

export const useAddMSO = () => {
  return useMutation(addMSO)
}
const keyClockAddMSO = async (payload: any) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/groups`

  const response = await keyClock.post(keyClockURL, payload)

  return response.data
}

export const useKeyClockAddMSO = () => {
  return useMutation(keyClockAddMSO)
}

export const keyClockDeleteMSO = async (group_name: any) => {
  const keyClockURL = commonCondition
    ? `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/mso/${group_name}`
    : `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/groups/${group_name}`

  const response = await keyClock.delete(keyClockURL)

  return response.data
}

export const useKeyClockDeleteMSO = () => {
  return useMutation(keyClockDeleteMSO)
}

const usePostSendMail = async (mailPayload: any) => {
  // const response = await xcpemSec.post(`${baseUrl}/api/wifi/reset-wifi-password`, mailPayload)
  let url = `${baseUrl}/api/v1/account/mso-created/mail`
  if (commonCondition) {
    url = `${baseUrl}/api/v1/notification/account/mso-created`
  }
  const response = await xcpemSec.post(`${url}`, mailPayload, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })
  return response.data
}

export const useSendMail = () => {
  return useMutation(usePostSendMail)
}
export const updateMSO = () => {
  const putRequest = async ({ values, id }: any) => {
    const response = await xcpemSec.patch(`${baseUrl}/webapp_white_labels_list?id=eq.${id}`, values)
    return response.data
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(putRequest)

  return { mutate, isLoading, error }
}

export const useGetMSO = (id: any) => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['get-MSO-detail'],
    async () => {
      const endpoint = `${baseUrl}/webapp_white_labels_list?id=eq.${id}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`MSO: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['get-MSO-detail'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const useDeleteMSO = (id: any) => {
  const [data, setData] = useState<any | undefined>('')
  const { isLoading, error, isFetching, isFetched, refetch, isError } = useQuery(
    ['delete-MSO'],
    async () => {
      const endpoint = `${baseUrl}/webapp_white_labels_list?id=eq.${id}`
      try {
        const response = await xcpemSec.delete<any>(endpoint)

        return response?.status
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          return error
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['delete-MSO'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, isError, refetch: handleRefetch }
}

export const useDeviceData = ({
  white_label,
  offset = 0,
  limit,
  inputValue = ''
}: any): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)

  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['device-data'],
    async () => {
      // const endpoint = white_label
      //   ? `${apiUrl}/webapp_dashboard_all_devices?white_label=eq.${white_label}&offset=${offset || ''}&limit=${
      //       limit || ''
      //     }&or=(email.ilike.*${encodeURIComponent(inputValue)}*,gw_uid.ilike.*${inputValue}*)`
      //   : `${apiUrl}/webapp_dashboard_all_devices?offset=${offset || 0}&limit=${
      //       limit || ''
      //     }&or=(email.ilike.*${encodeURIComponent(inputValue)}*,gw_uid.ilike.*${inputValue}*)`

      const params = new URLSearchParams()

      if (white_label) params.append('white_label', `eq.${white_label}`)
      if (offset !== undefined) params.append('offset', offset.toString())
      if (limit !== undefined) params.append('limit', limit.toString())

      // Add search filter
      params.append(
        'or',
        `(email.ilike.*${encodeURIComponent(inputValue) || ''}*,gw_uid.ilike.*${encodeURIComponent(inputValue) || ''}*)`
      )

      const endpoint = `${apiUrl}/webapp_dashboard_all_devices?${params.toString()}`

      try {
        const response = await xcpemSec.get<any[]>(endpoint, {
          headers: {
            Prefer: 'count=exact' // Add Prefer: count=exact header
          }
        })
        const newData = [{ data: response.data }, { total: response.headers['content-range'] }]

        return newData
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Devices: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['device-data'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const useManuallyDeviceData = ({
  white_label,
  offset = 0,
  limit,
  inputValue
}: any): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)

  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['manually-device-data'],
    async () => {
      const params = new URLSearchParams()

      if (white_label) params.append('white_label', `eq.${white_label}`)
      if (offset !== undefined) params.append('offset', offset.toString())
      if (limit !== undefined) params.append('limit', limit.toString())

      // Add search filter
      params.append(
        'or',
        `(email.ilike.*${encodeURIComponent(inputValue)}*,gw_uid.ilike.*${encodeURIComponent(inputValue)}*)`
      )

      const endpoint = `${apiUrl}/webapp_dashboard_all_devices?${params.toString()}`

      try {
        const response = await xcpemSec.get<any[]>(endpoint, {
          headers: {
            Prefer: 'count=exact' // Add Prefer: count=exact header
          }
        })
        const newData = [{ data: response.data }, { total: response.headers['content-range'] }]

        return newData
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Devices: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['manually-device-data'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

const addDevice = async (payload: any) => {
  try {
    const response = await xcpemSec.post(`${baseUrl}/rpc/webapp_gateway_bulk_white_labeling`, payload)
    return response.data
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return error?.response?.status
    } else {
      notifyError(`Add Device: ${error?.response?.data?.message || error?.message}`)
      throw new Error(error?.response?.data?.message || error?.message)
    }
  }
}

export const useAddDevice = () => {
  return useMutation(addDevice)
}

export const useGetWhiteLabel = (whiteLabel?: string) => {
  const [data, setData] = useState<any | undefined>({})
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['get-WhiteLabel-detail'],
    async () => {
      const endpoint = `${baseUrl}/webapp_white_labels_list?white_label=eq.${whiteLabel}`
      try {
        const response = await xcpemSec.get<any>(endpoint)
        return response.data[0]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`White Label: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )

  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['get-WhiteLabel-detail'] as readonly unknown[])
    refetch()
  }

  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const useGetMSOUsersList = ({
  group_name,
  limit,
  offset
}: {
  group_name: string
  limit?: number
  offset?: number
}) => {
  const [data, setData] = useState<any | undefined>({})
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['get-MSO-UsersList'],
    async () => {
      const endpoint = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/groups/${group_name}/members?only_admin_users=true`
      // const endpoint = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/groups/${group_name}/members?only_admin_users=true`
      try {
        const response = await xcpemSec.get<any>(endpoint)
        return response.data || []
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(
            `MSO Users List: ${error?.response?.data?.message || error?.response?.data?.errorMessage || error?.message}`
          )
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )

  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['get-MSO-UsersList'] as readonly unknown[])
    refetch()
  }

  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

const keyClockDeleteMSOUser = async ({ group_name, id }: { group_name: string; id: string }) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/users/${id}/mso/${group_name}`

  const response = await keyClock.delete(keyClockURL)

  return response.data
}

export const useKeyClockDeleteMSOUser = () => {
  return useMutation(keyClockDeleteMSOUser)
}

export const keyClockCreateUser = async (payload: any) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/users`

  const response = await keyClock.post(keyClockURL, payload)
  return response
}

export const useKeyClockCreateUser = () => {
  return useMutation(keyClockCreateUser)
}

export const keyClockEditUser = async (payload: any) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/users?user_id=${payload?.id}`

  const response = await keyClock.put(keyClockURL, {
    firstName: payload?.firstName,
    lastName: payload?.lastName
  })

  return response
}

export const useKeyClockEditUser = () => {
  return useMutation(keyClockEditUser)
}

export const GetMSOUserData = async (email: string) => {
  const encodedEmail = encodeURIComponent(email)
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/users?email=${encodedEmail}`

  const response = await keyClock.get(keyClockURL)
  return response
}

export const useMSOUserData = () => {
  return useMutation(GetMSOUserData)
}

export const MSOUserMailSend = async (id: string) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/users/${id}/send-verify-email`

  const response = await keyClock.put(keyClockURL)
  return response.data
}

export const useMSOUserMail = () => {
  return useMutation(MSOUserMailSend)
}

export const MSOUserAddInGroup = async ({
  group_name,
  id,
  is_super_admin
}: {
  group_name: string
  id: string
  is_super_admin?: boolean
}) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/users/${id}/mso`

  const response = await keyClock.post(keyClockURL, {
    mso_name: group_name,
    is_super_admin: is_super_admin
  })
  return response
}

export const useMSOUserAdd = () => {
  return useMutation(MSOUserAddInGroup)
}
