/* eslint-disable react-hooks/exhaustive-deps */
import { Grid2 as Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, useEffect } from 'react'
import { useStagingRouterStats } from '../../../../hook/cyber-security/useCyberSecurity'
import { useSessionContex } from '../../../../SessionContex'
import { convertScannedTrafficToReadableUnits, getColorCode } from '../../../../utils/helper'
import Loader from '../../../Loader/index'
import SecurityScore from '../../security-score'
import ThreatsBlocked from '../../threats-blocked'
import TrafficScanned from '../../traffic-scanned'
import './styles.scss'

interface Props {
  email: string
  mac: string
  gwuid: string
}

const SecurityOverviewStaging: FC<Props> = ({ email, mac, gwuid }) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const {
    data: routerStats,
    isFetching: RouterStatsFetching,
    refetch: routerStatsRefeching
  } = useStagingRouterStats(gwuid)

  useEffect(() => {
    routerStatsRefeching()
  }, [])

  useEffect(() => {
    if (reCall) {
      routerStatsRefeching()
    }
  }, [reCall])

  useEffect(() => {
    if (!RouterStatsFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [RouterStatsFetching])

  useEffect(() => {
    if (routerStats === 401) {
      !openModal && setOpenModal(true)
    }
  }, [routerStats])

  return (
    <div style={{ width: '100%' }} className='overflow-y-auto pb-20 security_overview_wrapper'>
      <Grid container spacing={2} className='security_overview_inner'>
        {RouterStatsFetching ? (
          <Loader />
        ) : routerStats ? (
          <>
            <Grid size={{ xs: 6, md: 4 }} style={{ paddingLeft: '0' }}>
              <SecurityScore
                title={'Security Score'}
                score={routerStats?.security_score || 0}
                score_suggestions={routerStats?.score_suggestions as any}
              />
            </Grid>
            <Grid size={{ xs: 6, md: 4 }}>
              <TrafficScanned
                trafficScanned={convertScannedTrafficToReadableUnits(routerStats?.total_traffic_scanned || 0)}
              />
            </Grid>
            <Grid size={{ xs: 6, md: 4 }}>
              <ThreatsBlocked email={email} mac={mac} count={routerStats?.stats} />
            </Grid>
          </>
        ) : (
          <Box marginLeft={2.5} paddingBottom={4} color={getColorCode('redToBlue')}>
            Router Stats data not found
          </Box>
        )}
      </Grid>
    </div>
  )
}

export default SecurityOverviewStaging
