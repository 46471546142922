import React, { useEffect, useState } from 'react'
import { TableCell } from '@mui/material'
import { ArrowDown, ArrowUp } from 'iconsax-react'

const TableHeader = ({ column, onClick, filterType }: any) => {
  const [sortOrder, setSortOrder] = useState('none') // Default sorting order is asc

  const handleSortStatus = () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc')
    } else if (sortOrder === 'desc') {
      setSortOrder('reset') // Set it to 'none' to remove the filter
    } else {
      setSortOrder('asc') // Cycle back to asc
    }
  }

  useEffect(() => {
    if (filterType) {
      onClick(column.filter, sortOrder)
    }
  }, [sortOrder])

  return (
    <>
      <TableCell
        key={column.id}
        align={column.align}
        sx={{
          fontWeight: '600',
          minWidth: column.minWidth,
          maxWidth: column.maxWidth,
          background: '#f7f8fe'
          // textTransform: 'uppercase'
        }}
        onClick={handleSortStatus}
        className='cursor-pointer'
        // onMouseEnter={() => {
        //   setShow(true)
        // }}
        // onMouseLeave={() => {
        //   setShow(false)
        // }}
      >
        <div className='d-flex'>
          {column.label}
          {filterType && filterType.cell !== 'ott_serial' && (
            <div className='cursor-pointer d-flex justify-center' style={{ width: '25px', height: '25px' }}>
              {filterType.type === 'asc' && filterType.cell === column.filter ? (
                <ArrowUp size='16' color='#41a129' className='fw-arrow' /> // MUI arrow up icon
              ) : filterType.type === 'desc' && filterType.cell === column.filter ? (
                <ArrowDown size='16' color='#e84e6a' className='fw-arrow' />
              ) : null}
            </div>
          )}
        </div>
      </TableCell>
    </>
  )
}

export default TableHeader
