/* eslint-disable no-restricted-globals */
import { createContext, ReactNode, useState } from 'react'

interface Breadcrumb {
  label?: string
  href?: string
}

interface BreadcrumbContextType {
  breadcrumbData: Breadcrumb[]
  updateBreadcrumbData: (newBreadcrumbData: Breadcrumb[]) => void
  filters: BreadcrumbFilter[] | null
  updateFilters: (newFilters: BreadcrumbFilter[]) => void
  disabled?: boolean
  updateDisabled?: any
}

export const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumbData: [
    {
      label: 'Home',
      href: '/'
    }
  ],
  updateBreadcrumbData: () => {},
  filters: [],
  updateFilters: () => {},
  disabled: true,
  updateDisabled: () => {}
})

interface BreadcrumbProviderProps {
  children: ReactNode
}

interface BreadcrumbFilter {
  label: string
  filterKey: string
  options: string[]
  useLowerCase?: boolean
  append?: string
}

export const BreadcrumbProvider = ({ children }: BreadcrumbProviderProps) => {
  const [breadcrumbData, setBreadcrumbData] = useState<Breadcrumb[]>([
    {
      label: 'Home',
      href: '/'
    }
  ])

  const updateBreadcrumbData = (newBreadcrumbData: Breadcrumb[]) => {
    setBreadcrumbData(newBreadcrumbData)
  }

  const [filters, setFilters] = useState<BreadcrumbFilter[]>([
    {
      filterKey: 'alertType',
      options: ['Open', 'Closed'],
      useLowerCase: true,
      label: 'Open',
      append: 'Alerts'
    }
  ])

  const updateFilters = (newFilters: BreadcrumbFilter[]) => {
    setFilters(newFilters)
  }
  const [disabled, setDisabled] = useState<boolean>(true)

  const updateDisabled = (newDisabled: boolean) => {
    setDisabled(newDisabled)
  }

  return (
    <BreadcrumbContext.Provider
      value={{ breadcrumbData, updateBreadcrumbData, filters, updateFilters, updateDisabled, disabled }}
    >
      {children}
    </BreadcrumbContext.Provider>
  )
}
