import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { v4prifix } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { commonCondition } from '../lib/utils'

export const useSpeedtest = (
  gwuid: string,
  from?: string
): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)

  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['speedtest'],
    async () => {
      if (!gwuid || gwuid === '') return undefined
      // const queryString = from ? `/rpc/speedtest?_gw_uid=${gwuid}&_from=${from}` : `/rpc/speedtest?_gw_uid=${gwuid}`
      const queryString = from
        ? commonCondition
          ? `${v4prifix}/speedtest?gw_uuid=${gwuid}&timestamp_from=${from}`
          : `/rpc/speedtest?_gw_uid=${gwuid}&_from=${from}`
        : commonCondition
        ? `${v4prifix}/speedtest?gw_uuid=${gwuid}`
        : `/rpc/speedtest?_gw_uid=${gwuid}`

      try {
        const response = await xcpemSec.get<any[]>(queryString)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Speed test: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['speedtest'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const useBulkSpeedtest = (
  gwuids: string
): {
  data: any[] | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)

  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['bulk-speedtest'],
    async () => {
      const queryString = `/rpc/speedtest_arr?_gw_uid=${gwuids}`

      try {
        const response = await xcpemSec.get<any[]>(queryString)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Bulk speed test: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 1
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['bulk-speedtest'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
