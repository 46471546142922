import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { Button, Switch, Tag } from 'antd'
import React, { useState } from 'react'
import CustomerModal from '../../../components/Customer-table/CustomerModal'
import { GatewayUserLists } from '../../../components/GroupsTable/types'
import { getStatusColor } from '../../../lib/utils'
import Registor from '../../MSO/Registor'
import { useSessionContex } from '../../../SessionContex'

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold'
})

interface UserInformationProps {
  userData: GatewayUserLists
  setSnackBarInfo?: any
}

const UserInformation = ({ userData, setSnackBarInfo }: UserInformationProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [provistionId, setProvistionId] = useState('')
  const { setReCall } = useSessionContex()

  return (
    <React.Fragment>
      <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>General User Information</h2>
      <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>GATEWAY UID</StyledTableCell>
              <StyledTableCell>FIRMWARE</StyledTableCell>
              <StyledTableCell>MODEL</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>UP TIME</StyledTableCell>
              <StyledTableCell>REGISTERED E-MAIL</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{userData.gw_uid}</TableCell>
              <TableCell>{userData.fw_version}</TableCell>
              <TableCell>{userData.model}</TableCell>
              <TableCell>
                <span
                  style={{
                    color: getStatusColor(userData.status),
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    letterSpacing: '0.025em'
                  }}
                >
                  {userData.status}
                </span>
              </TableCell>
              <TableCell>{userData.uptime}</TableCell>
              <TableCell>
                {userData?.user_email ? (
                  userData?.user_email
                ) : (
                  <Button
                    className='!bg-[#6C83FF] !text-white px-3 py-2 gap-2 rounded-xl text-[13px] flex items-center'
                    onClick={() => {
                      setProvistionId(userData.gw_uid)
                      setIsModalOpen(true)
                    }}
                  >
                    Register User
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 5 }}>
        <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>User Roles</h2>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <div className='flex items-center gap-1'>
            <Switch size='default' checked={userData.is_vip} disabled />
            <label className='text-gray-700 text-md font-semibold'>VIP</label>
          </div>
          <div className='flex items-center gap-1'>
            <Switch size='default' checked={userData.is_friendly} disabled />
            <label className='text-gray-700 text-md font-semibold'>FRIENDLY</label>
          </div>
          <div className='flex items-center gap-1'>
            <Switch size='default' checked={userData.is_developer} disabled />
            <label className='text-gray-700 text-md font-semibold'>DEV</label>
          </div>
        </Box>
      </Box>
      <Box sx={{ mt: 5 }}>
        <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>MSO Name</h2>
        {userData?.whitelabel ? (
          <Tag className='m-0 w-auto rounded-full text-wrap font-medium px-3 py-1 bg-[#EFF2FC] border-0'>
            {userData?.whitelabel}
          </Tag>
        ) : (
          'No MSO Found!!!'
        )}
      </Box>
      <CustomerModal
        open={isModalOpen}
        handleClose={() => {
          setProvistionId('')
          setIsModalOpen(false)
        }}
      >
        <Registor
          handleClose={() => {
            setProvistionId('')
            setIsModalOpen(false)
          }}
          deviceGwId={provistionId}
          setSnackBarInfo={setSnackBarInfo}
          setReCall={setReCall}
        />
      </CustomerModal>
    </React.Fragment>
  )
}

export default UserInformation
