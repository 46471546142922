import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { Switch } from 'antd'
import React from 'react'
import { Betches } from '../../../components/GroupsTable/types'

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold'
})

interface BatchConfigurationsProps {
  batchData: Betches
}

const BatchConfigurations = ({ batchData }: BatchConfigurationsProps) => {
  return (
    <React.Fragment>
      <Box sx={{ mt: 5 }}>
        <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>Batch Configurations</h2>
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>DEVELOPER ROUTERS ONLY</StyledTableCell>
                <StyledTableCell>MANUAL LAUNCH</StyledTableCell>
                <StyledTableCell>RESET GATEWAY</StyledTableCell>
                <StyledTableCell>AUTO CONTINUE TO NEXT SEGMENT</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className='flex items-center gap-1'>
                    <Switch size='default' checked={batchData?.is_developer_only} disabled />
                    <label className='text-gray-700 text-md'>{batchData?.is_developer_only ? 'YES' : 'NO'}</label>
                  </div>
                </TableCell>
                <TableCell>
                  <div className='flex items-center gap-1'>
                    <Switch size='default' checked={batchData?.manual_launch} disabled />
                    <label className='text-gray-700 text-md'>{batchData?.manual_launch ? 'YES' : 'NO'}</label>
                  </div>
                </TableCell>
                <TableCell>
                  <div className='flex items-center gap-1'>
                    <Switch size='default' checked={batchData?.reset_gateway} disabled />
                    <label className='text-gray-700 text-md'>{batchData?.reset_gateway ? 'YES' : 'NO'}</label>
                  </div>
                </TableCell>
                <TableCell>
                  <div className='flex items-center gap-1'>
                    <Switch size='default' checked={batchData?.auto_continue_next_segment} disabled />
                    <label className='text-gray-700 text-md'>
                      {batchData?.auto_continue_next_segment ? 'YES' : 'NO'}
                    </label>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  )
}

export default BatchConfigurations
