/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Firmware from '../../../../common-images/app-logo/customer-images/Firmware'
import Loader from '../../../../components/Loader'
import Pagination from '../../../../components/Pagination/index'
import { useGatewayParentalLogs } from '../../../../hook/useGatewayLogs'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { useSessionContex } from '../../../../SessionContex'
import { getColorCode } from '../../../../utils/helper'

const ParentalLog = () => {
  const activeGw_Uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const limit = 6
  const [offset, setOffset] = useState<any>(0)

  const { data, isFetching, refetch } = useGatewayParentalLogs(offset, limit)

  useEffect(() => {
    refetch()
  }, [offset, limit, activeGw_Uid])

  useEffect(() => {
    if (reCall) {
      refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])

  const handleChangePage = (newPage: number) => {
    const newOffset = (newPage - 1) * limit
    setOffset(newOffset)
  }

  return (
    <>
      <div className='container'>
        {isFetching ? (
          <Loader />
        ) : data && data !== undefined && data[0] && data[0]?.data && data[0]?.data?.length > 0 ? (
          data[0]?.data?.map((activity: any, index: any) => (
            <div key={index} className='row active-activity-details-cls mb-1'>
              <div className='col-8'>
                <div className='row'>
                  <div className='col-12 d-flex'>
                    <div className='border activity-logo-cls'>
                      <Firmware />
                    </div>
                    <div className='ps-2'>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#707e8c'
                            }
                          }
                        }}
                        title={activity?.title}
                      >
                        <label className='activities-label-cls' title={activity?.title}>
                          {activity?.title}
                        </label>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#707e8c'
                            }
                          }
                        }}
                        title={activity?.description}
                      >
                        <p className='activities-desc-cls'>
                          {activity?.description?.length > 20
                            ? activity?.description.substring(0, 20) + '...'
                            : activity?.description}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4 pt-2 ps-4  d-flex'>
                <label className='activities-desc-cls pe-2'>
                  {
                    // moment
                    //   .tz(moment.utc(activity.event_time), timeZone)
                    //   .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`)
                    moment.utc(activity?.event_time).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                  }
                </label>
                {/* <label>
                  <OnlineOfflineImage fillColor={getColorCode('Online')} width='10px' height='10px' />
                </label> */}
              </div>
            </div>
          ))
        ) : (
          <div
            className='no-data-found text-center pt-4'
            style={{ color: getColorCode('redToBlue') }}
            data-testid='testid_parentalLog_activity_no_data'
          >
            No data found
          </div>
        )}
      </div>

      <div className='fixed-pagination-container'>
        {data && data[1] && data[1]?.total?.split('/')[1] > 0 && (
          <>
            <Pagination
              onePage={limit}
              totalPages={data[1].total.split('/')[1]}
              handleSetCurrentCount={handleChangePage}
              displayTotal={true}
            />
          </>
        )}
      </div>
    </>
  )
}

export default ParentalLog
