/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Firmware from '../../../../common-images/app-logo/customer-images/Firmware'
import Loader from '../../../../components/Loader'
import Pagination from '../../../../components/Pagination'
import { useGatewayAdminLogs, useGatewayAllAdminLogs } from '../../../../hook/useGatewayLogs'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { useSessionContex } from '../../../../SessionContex'
import { getColorCode } from '../../../../utils/helper'

const SupportAgentLog = () => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const [offset, setOffset] = useState(0)
  const [pageLoader, setPageLoader] = useState(true)
  const activeGw_Uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { data: allAdminData, refetch: allDataRefech } = useGatewayAllAdminLogs(activeGw_Uid)

  const { data, isFetching, refetch } = useGatewayAdminLogs(offset, activeGw_Uid, 6)
  useEffect(() => {
    if (allAdminData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [allAdminData])

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])

  useEffect(() => {
    allDataRefech()
  }, [])

  useEffect(() => {
    if (reCall) {
      refetch()
      allDataRefech()
    }
  }, [reCall])

  useEffect(() => {
    if (!(pageLoader || isFetching)) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [pageLoader, isFetching])

  useEffect(() => {
    setPageLoader(true)
    refetch()
    setTimeout(() => {
      setPageLoader(false)
    }, 1000)
  }, [offset, activeGw_Uid])

  useEffect(() => {
    setTimeout(() => {
      setPageLoader(false)
    }, 1000)
  }, [data])

  return (
    <>
      <div className='container'>
        {/* <div className='row active-activity-cls mb-1'>
          <div className='col-8'>
            <div className='row'>
              <div className='col-12 d-flex'>
                <div className='border activity-logo-cls'>
                  <Firmware />
                </div>
                <div className='ps-2'>
                  <label className='activities-label-cls'>FW upgraded Successfully</label>
                  <p className='activities-desc-cls'>FW Has been upgraded to 1.9.3</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-4 pt-2 ps-4'>
            <label className='activities-desc-cls pe-2'>9/5/23 11:13</label>
            <label>
              <BreadcrumbsArrowRight />
            </label>
          </div>
        </div> */}
        {pageLoader || isFetching ? (
          <Loader />
        ) : data?.length > 0 ? (
          data?.map((activity: any, index: any) => (
            <div key={index} className='row active-activity-details-cls mb-1'>
              <div className='col-8'>
                <div className='row'>
                  <div className='col-12 d-flex'>
                    <div className='border activity-logo-cls'>
                      <Firmware />
                    </div>
                    <div className='ps-2'>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#707e8c'
                            }
                          }
                        }}
                        title={activity?.event}
                      >
                        <label className='activities-label-cls'>{activity?.event}</label>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#707e8c'
                            }
                          }
                        }}
                        title={activity?.derscription.length > 20 ? activity?.derscription : ''}
                      >
                        <p className='activities-desc-cls'>
                          {activity?.derscription.length > 20
                            ? activity?.derscription.substring(0, 20) + '...'
                            : activity?.derscription}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4 pt-2 ps-4  d-flex'>
                <label className='activities-desc-cls pe-2'>
                  {
                    moment.utc(activity.timestamp).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                    // moment
                    //   .tz(moment.utc(activity.timestamp), timeZone)
                    //   .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`)
                  }
                </label>
                {/* <label>
                  <OnlineOfflineImage fillColor={getColorCode('Online')} width='10px' height='10px' />
                </label> */}
              </div>
            </div>
          ))
        ) : (
          <div
            className='no-data-found text-center pt-4'
            style={{ color: getColorCode('redToBlue') }}
            data-testid='testid_supportAgentLog_activity_no_data'
          >
            No data found
          </div>
        )}
      </div>
      <div className='fixed-pagination-container'>
        {data?.length > 0 && (
          <Pagination
            onePage={6}
            totalPages={allAdminData?.length}
            handleSetCurrentCount={(newPage: any) => {
              // setOffset(newPage)
              const newOffset = (newPage - 1) * 6
              setOffset(newOffset)
            }}
            displayTotal={true}
          />
        )}
      </div>
    </>
  )
}

export default SupportAgentLog
