/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from '@mui/icons-material'
import { Box, Button, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { setProvisionEmail } from '../../redux/dashboard/dashboardCount'
import { DashboardRootState } from '../../redux/dashboard/types'
import { getColorCode } from '../../utils/helper'

const Registor = ({ handleClose, deviceGwId, setSnackBarInfo, setCallDeviceDetail, setReCall }: any) => {
  const validationSchema = Yup.object().shape({
    registeredTo: Yup.string().email('Please enter a valid email').required('Please enter registered email')
  })

  const dispatch: any = useDispatch()
  const theme = useTheme()
  const showingProvisionModelError = useSelector((state: DashboardRootState) => state.dashboardCount.error)
  const showingProvisionModelSuccess = useSelector(
    (state: DashboardRootState) => state.dashboardCount.provisionSuccessMessage
  )

  const formik: any = useFormik({
    initialValues: {
      registeredTo: ''
    },
    onSubmit: (values: any, actions: any) => {
      actions.setSubmitting(true)
      setTimeout(() => {
        actions.setSubmitting(false)
      }, 500)
      dispatch(setProvisionEmail({ params: { _email: values.registeredTo, _gw_uid: deviceGwId } }))
    },
    validationSchema
  })
  useEffect(() => {
    if (!!showingProvisionModelError) {
      setSnackBarInfo({
        isOpen: true,
        message: showingProvisionModelError,
        type: 'redToRed',
        severity: 'error'
      })
      dispatch({ type: 'SET_PROVISION_EMAIL_RESET' })
    }
  }, [showingProvisionModelError])

  useEffect(() => {
    if (!!showingProvisionModelSuccess) {
      setSnackBarInfo({
        isOpen: true,
        message: 'Provision account successfully',
        type: 'Closed',
        severity: 'success'
      })
      dispatch({ type: 'SET_PROVISION_EMAIL_RESET' })

      setCallDeviceDetail && setCallDeviceDetail?.(true)
      handleClose()
      setReCall && setReCall?.(true)
    }
  }, [showingProvisionModelSuccess])
  return (
    <Box className='rounded'>
      <Box
        sx={{
          width: '500px',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2}>
          <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>Provision Device</h3>
          <Close
            className='cursor-pointer'
            onClick={() => {
              formik.resetForm()
              handleClose()
            }}
          />
        </Box>
        <Box className='pb-3 bg-[#f7f8fe] pt-1 text-center' paddingLeft={5} paddingRight={5}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box marginTop={2} display={'flex'} alignItems={'center'}>
              <label style={{ textWrap: 'nowrap', fontWeight: 'bold' }}>GW UID:&nbsp; </label>
              {deviceGwId}
            </Box>
            <Box marginTop={2} display={'flex'} alignItems={'center'} sx={{ gap: '2px' }}>
              <Box>
                <label style={{ textWrap: 'nowrap', fontWeight: 'bold' }}>Provision To: &nbsp;</label>
                {formik.errors.registeredTo && <Box height={'24px'}></Box>}
              </Box>

              <Box width={'100%'}>
                <input
                  type='text'
                  className='p-2 rounded-[10px] w-full border-[1px] verifypin-form-input'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.registeredTo}
                  name='registeredTo'
                  placeholder='Please enter email address'
                  style={
                    formik.errors.registeredTo
                      ? { border: `1px solid ${getColorCode('redToBlue')}` }
                      : { border: `1px solid #ccc` }
                  }
                  data-testid='testid_provision_device_input'
                />
                {formik.errors.registeredTo && (
                  <span
                    className='errorText'
                    style={{ color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }}
                  >
                    {formik.errors.registeredTo}
                  </span>
                )}
              </Box>
            </Box>
            <div className='mt-4 mb-3'>
              <Button
                type='submit'
                variant='contained'
                size='medium'
                sx={{
                  marginRight: '10px',
                  background: getColorCode('infoBlue'),
                  '&:hover': {
                    backgroundColor: getColorCode('infoBlue')
                  }
                }}
                disabled={formik.isSubmitting}
                data-testid='testid_provision_device_button'
              >
                Provision Device
              </Button>

              {/* <Button
                onClick={() => {
                  formik.resetForm()
                  handleClose()
                }}
                size='medium'
                variant='outlined'
                sx={{
                  color: getColorCode('infoBlue'),
                  borderColor: getColorCode('infoBlue')
                }}
              >
                Cancel
              </Button> */}
            </div>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
export default Registor
