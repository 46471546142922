/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Card, FormControl, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CommonModal from '../../../../components/GroupsTable/CommonModal/CommonModal'
import SelectInput from '../../../../components/GroupsTable/SelectInput'
import Loader from '../../../../components/Loader'
import { useGetCustomerAndCount } from '../../../../hook/useAllCustomers'
import {
  useCellularGWUsageStats,
  useCellularGWUsageSummary,
  useCellularSummary,
  useCellularUsage,
  useCellularUsageGraph,
  useCellularUsageSummary
} from '../../../../hook/useCellularUsageSummary'
import { getCustomerStatisticsCount } from '../../../../redux/customerStatistics/customerStatistics'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { convertMBSpeedParameter, convertScannedTrafficToReadableUnits, getColorCode } from '../../../../utils/helper'
import CellularUsageTable from './CellularUsageTable'

interface CellularModalProps {
  cellularModalOpen: boolean
  setCellularModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isDashboard?: boolean
  cellularFetchData?: {
    download_data_mb: number
    upload_data_mb: number
  }
}

// Type definitions
interface ChartDataPoint {
  time: string
  usage: number
  deviceCount: number
}

export interface TableDataRow {
  id: number
  date: string
  device?: string
  download_data_mb?: string
  upload_data_mb?: string
  startTime: string
  endTime: string
  duration_seconds: string
  cellularUsage: string
}

export interface Column {
  id:
    | 'date'
    | 'startTime'
    | 'endTime'
    | 'duration_seconds'
    | 'cellularUsage'
    | 'device'
    | 'download_data_mb'
    | 'upload_data_mb'
  label: string
  minWidth: number
}

type CustomerStatistics = {
  id: number
  white_label: string
  customer_name: string | null
  customer_address: string
  customer_contact_person: string
  customer_phone: string
  customer_email: string
  created_date: string
}

const CellularModal: React.FC<CellularModalProps> = ({ cellularModalOpen, setCellularModalOpen, isDashboard }) => {
  const dispatch = useDispatch()
  const [FilterData, setFilterData] = useState<any>(24)
  const [selectedMSOId, setSelectedMSOId] = useState<string>('')
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  // Calculate the timestamp based on the selected filter value
  const getPastTimestamp = (filterValue: any) => {
    if (filterValue === 24) {
      return moment().subtract(24, 'hours').toISOString()
    } else if (filterValue === 1) {
      return moment().subtract(1, 'hours').toISOString() // For 'Last 1 hr'
    } else if (filterValue === 168) {
      return moment().subtract(7, 'days').toISOString() // For 'This Week'
    } else if (filterValue === 730) {
      return moment().subtract(1, 'months').toISOString() // For 'This Month'
    }
    return moment().toISOString()
  }

  const pastTimestamp = getPastTimestamp(FilterData)
  const { data, isLoading, refetch: cellularUsageFetch } = useCellularUsage(activeGw_Uid, pastTimestamp)
  const { data: MSOData, refetch: MSOFetch, isLoading: MSOLoading } = useGetCustomerAndCount()
  const customerStatistics = useSelector((state: any) => state?.customerStatistics?.totalData)

  // console.log('Customer Statistics:', customerStatistics)

  // const { data, isFetching, refetch: cellularUsageDataFetch } = useCellularUsageData(activeGw_Uid, pastTimestamp)

  const {
    data: cellularUsageGraphData,
    isFetching: cellularUsageDataFetchingGraph,
    refetch: cellularUsageGraphRefetch
  } = useCellularUsageGraph(activeGw_Uid, pastTimestamp)

  const {
    data: cellularGWUsageSummary,
    isFetching: cellularGWUsageSummaryFetchingGraph,
    refetch: cellularGWUsageSummaryGraphRefetch
  } = useCellularGWUsageSummary(selectedMSOId, pastTimestamp)

  const {
    data: cellularGWUsageStats,
    isFetching: cellularGWUsageStatsFetchingGraph,
    refetch: cellularGWUsageStatsGraphRefetch
  } = useCellularGWUsageStats(
    selectedMSOId,
    pastTimestamp,
    FilterData === 24 || FilterData === 1 ? (FilterData === 1 ? 'minute' : 'hour') : 'day'
  )

  const {
    data: cellularSummaryData,
    isFetching: cellularSummaryFetching,
    refetch: cellularSummaryFetch
  } = useCellularSummary(selectedMSOId, pastTimestamp)

  const {
    data: cellularUsageSummaryData,
    isFetching: cellularUsageSummaryFetching,
    refetch: cellularUsageSummaryFetch
  } = useCellularUsageSummary(activeGw_Uid, pastTimestamp)

  useEffect(() => {
    if (activeGw_Uid !== '' && cellularModalOpen && !isDashboard) {
      // cellularUsageDataFetch()
      cellularUsageGraphRefetch()
      cellularUsageSummaryFetch()
      cellularUsageFetch()
    }
  }, [activeGw_Uid, cellularModalOpen, FilterData])

  const MSOfunction = async () => {
    await dispatch(getCustomerStatisticsCount())
  }

  useEffect(() => {
    if (isDashboard && cellularModalOpen) {
      cellularGWUsageSummaryGraphRefetch()
      cellularGWUsageStatsGraphRefetch()
      cellularSummaryFetch()
      if (
        selectedMSOId === '' &&
        process.env.REACT_APP_REALM !== 'Hitron' &&
        process.env.REACT_APP_REALM !== 'entpnt'
      ) {
        MSOfunction()
      }
    }
  }, [isDashboard, FilterData, cellularModalOpen, selectedMSOId])

  const generateChartData = () => {
    return isDashboard
      ? cellularGWUsageStats?.map((item: any) => ({
          time:
            FilterData === 24 || FilterData === 1
              ? moment.utc(item.time_interval).local().format('h:mm A') // Format time for Last 24 hours or Live data
              : moment.utc(item.time_interval).local().format('DD-MM-YYYY'), // Full date for Last 7 Days or Last 30 Days
          downloadUsage: item.download_data_mb,
          uploadUsage: item.upload_data_mb,
          deviceCount: item.device_count
        }))
      : cellularUsageGraphData?.map((item: any) => ({
          time:
            FilterData === 24 || FilterData === 1
              ? moment.utc(item.timestamp).local().format('h:mm A') // Format time for Last 24 hours or Live data
              : moment.utc(item.timestamp).local().format('DD-MM-YYYY'), // Full date for Last 7 Days or Last 30 Days
          downloadUsage: item.download_data_mb,
          uploadUsage: item.upload_data_mb
        }))
  }

  const chartData = generateChartData()

  const calculateAverageUsage = (data: any[]) => {
    if (!data || data?.length === 0) return { downloadAverage: 0, uploadAverage: 0 }

    const totalDownloadUsage = data?.reduce((acc, item) => acc + item?.downloadUsage, 0)
    const totalUploadUsage = data?.reduce((acc, item) => acc + item?.uploadUsage, 0)

    return {
      downloadAverage: totalDownloadUsage / data?.length,
      uploadAverage: totalUploadUsage / data?.length
    }
  }

  const { downloadAverage, uploadAverage } = calculateAverageUsage(chartData)

  function convertThroughput(sim_thrput_rx: number, duration_seconds: number) {
    // Convert kbps to MB per second
    const mbPerSecond = sim_thrput_rx / 1048576

    return mbPerSecond.toFixed(2)
  }

  const tableData: TableDataRow[] = isDashboard
    ? cellularGWUsageSummary &&
      cellularGWUsageSummary?.length &&
      cellularGWUsageSummary?.map((item: any, index: number) => {
        return {
          id: index + 1,
          device: item.gw_uid,
          download_data_mb: convertMBSpeedParameter(item.download_data_mb, 2),
          upload_data_mb: convertMBSpeedParameter(item.upload_data_mb, 2)
        }
      })
    : cellularUsageSummaryData &&
      cellularUsageSummaryData?.length &&
      cellularUsageSummaryData?.map((item: any, index: number) => {
        const startTime = moment.utc(item.start_time).local()
        const endTime = moment.utc(item.end_time).local()
        const durationMinutes = item.duration_seconds

        return {
          id: index + 1,
          date: startTime.format('MMMM DD, YYYY'),
          startTime: startTime.format('h:mm A'),
          endTime: endTime.format('h:mm A'),
          duration_seconds: `${durationMinutes}s`,
          cellularUsage: convertScannedTrafficToReadableUnits(item.sim_thrput_rx, 2)
        }
      })

  // Column definitions for the table
  const columns: Column[] = [
    { id: 'date', label: 'Date', minWidth: 170 },
    { id: 'device', label: 'DEVICE', minWidth: 170 },
    { id: 'startTime', label: 'Start Time', minWidth: 100 },
    { id: 'endTime', label: 'End Time', minWidth: 100 },
    { id: 'duration_seconds', label: 'Duration (sec)', minWidth: 100 },
    { id: 'cellularUsage', label: 'Cellular Usage', minWidth: 100 }
  ]

  const dashboardColumns: Column[] = [
    { id: 'device', label: 'DEVICE', minWidth: 170 },
    { id: 'download_data_mb', label: 'Download', minWidth: 100 },
    { id: 'upload_data_mb', label: 'Upload', minWidth: 100 }
  ]

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string
    setFilterData(selectedValue)
  }

  const chartOptions: Highcharts.Options = {
    title: {
      text: ''
    },
    xAxis: {
      categories: chartData?.map((point: any) => point?.time || ''), // Use empty string if no new date
      title: {
        text: FilterData === 24 || FilterData === 1 ? 'Time' : 'Date',
        margin: 20
      }
    },
    yAxis: [
      {
        title: { text: 'Usage (MB)', margin: 20 },
        gridLineColor: '#e6e6e6'
      },
      {
        title: { text: 'Device Count' },
        opposite: true,
        visible: FilterData === 1 ? false : isDashboard,
        allowDecimals: false
      }
    ],
    series: isDashboard
      ? FilterData !== 1
        ? [
            {
              type: 'line',
              name: 'Download Usage',
              data: chartData?.map((point: any) => point?.downloadUsage),
              color: getColorCode('DownloadUsage')
            },
            {
              type: 'line',
              name: 'Upload Usage',
              data: chartData?.map((point: any) => point?.uploadUsage),
              color: getColorCode('UploadUsage')
            },
            {
              type: 'line',
              name: 'Device Count',
              data: chartData?.map((point: any) => point?.deviceCount),
              color: getColorCode('DeviceUsage'),
              yAxis: 1
            }
          ]
        : [
            {
              type: 'line',
              name: 'Download Usage',
              data: chartData?.map((point: any) => point?.downloadUsage),
              color: getColorCode('DownloadUsage')
            },
            {
              type: 'line',
              name: 'Upload Usage',
              data: chartData?.map((point: any) => point?.uploadUsage),
              color: getColorCode('UploadUsage')
            }
          ]
      : [
          {
            type: 'line',
            name: 'Download Usage',
            data: chartData?.map((point: any) => point?.downloadUsage),
            color: getColorCode('DownloadUsage')
          },
          {
            type: 'line',
            name: 'Upload Usage',
            data: chartData?.map((point: any) => point?.uploadUsage),
            color: getColorCode('UploadUsage')
          },
          {
            type: 'line',
            name: 'Average Download Usage',
            data: new Array(chartData?.length).fill(Number(downloadAverage.toFixed(2))),
            color: getColorCode('DownloadUsage'), // Different color for average line
            dashStyle: 'Dash' // Optional: Makes it a dashed line
          },
          {
            type: 'line',
            name: 'Average Upload Usage',
            data: new Array(chartData?.length).fill(Number(uploadAverage.toFixed(2))),
            color: getColorCode('UploadUsage'), // Different color for average line
            dashStyle: 'Dash' // Optional: Makes it a dashed line
          }
        ],
    credits: {
      enabled: false
    },
    legend: { enabled: true },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (this.x !== undefined && this.y !== undefined) {
          const usagePoint = chartData?.[this.point.index]
          let tooltipContent = `<b>${this.x}</b><br/>`
          if (isDashboard) {
            if (this.series.name === 'Download Usage') {
              tooltipContent += `Download Usage: ${convertMBSpeedParameter(
                Number(Number(usagePoint?.downloadUsage).toFixed(2)),
                2
              )}`
            } else if (this.series.name === 'Upload Usage') {
              tooltipContent += `Upload Usage: ${convertMBSpeedParameter(
                Number(Number(usagePoint?.uploadUsage).toFixed(2)),
                2
              )}`
            } else if (this.series.name === 'Device Count') {
              tooltipContent += `Device Count: ${usagePoint?.deviceCount}`
            }
          } else {
            if (this.series.name === 'Download Usage') {
              tooltipContent += `Download: ${convertMBSpeedParameter(Number(usagePoint.downloadUsage.toFixed(2)), 2)}`
            } else if (this.series.name === 'Upload Usage') {
              tooltipContent += `Upload: ${convertMBSpeedParameter(Number(usagePoint.uploadUsage.toFixed(2)), 2)}`
            } else if (this.series.name === 'Average Download Usage') {
              tooltipContent += `Avg Download: ${convertMBSpeedParameter(Number(downloadAverage?.toFixed(2)), 2)}`
            } else if (this.series.name === 'Average Upload Usage') {
              tooltipContent += `Avg Upload: ${convertMBSpeedParameter(Number(uploadAverage?.toFixed(2)), 2)}`
            }
          }
          return tooltipContent
        }
        return 'Invalid data'
      }
    }
  }

  // Filter columns to conditionally include the 'device' column based on isDashboard
  const filteredColumns = isDashboard ? dashboardColumns : columns.filter(column => column.id !== 'device')

  const msoOptions = useMemo(() => {
    if (!customerStatistics || customerStatistics?.length === 0) return []

    return (
      customerStatistics &&
      customerStatistics?.map((response: CustomerStatistics) => ({
        value: String(response.id),
        label: response.white_label
      }))
    )
  }, [customerStatistics])

  return (
    <>
      <CommonModal
        maxWidth={'lg'}
        open={cellularModalOpen}
        onClose={() => {
          setCellularModalOpen(false)
          setFilterData(24)
          setSelectedMSOId('')
        }}
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 10,
              width: '100%'
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, width: '100%' }}
            >
              <Typography variant='h6' color='#3D4854'>
                Cellular Usage
              </Typography>

              {/* {FilterData !== 'custom' && (
                <Typography variant='h6' color='#3D4854'>
                  {FilterData === 24
                    ? 'Last 24 Hours'
                    : FilterData === 1
                    ? 'Live data'
                    : FilterData === 168
                    ? 'Last 7 Days'
                    : FilterData === 730
                    ? 'Last 30 Days'
                    : ''}
                </Typography>
              )} */}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mr: 2 }}>
              {isDashboard && process.env.REACT_APP_REALM !== 'Hitron' && process.env.REACT_APP_REALM !== 'entpnt' && (
                <SelectInput
                  loading={MSOLoading}
                  options={msoOptions}
                  placeholder='Please select MSO'
                  value={selectedMSOId}
                  onChange={value => {
                    if (selectedMSOId) {
                      setSelectedMSOId('')
                    }
                    setSelectedMSOId(value)
                  }}
                  parentClassName='w-[250px]'
                  className={`h-[4.45vh] !rounded-sm placeholder:!text-[#000]`}
                  onClear={() => {
                    setSelectedMSOId('')
                  }}
                />
              )}
              <FormControl sx={{ width: '180px' }}>
                <Select
                  id='demo-simple-select'
                  value={FilterData}
                  onChange={handleChange}
                  className='text-uppercase'
                  size='small'
                >
                  <MenuItem value={1} className='text-uppercase'>
                    Live data
                  </MenuItem>
                  <MenuItem value={24} className='text-uppercase'>
                    Last 24 Hours
                  </MenuItem>
                  <MenuItem value={168} className='text-uppercase'>
                    Last 7 Days
                  </MenuItem>
                  <MenuItem value={730} className='text-uppercase'>
                    Last 30 Days
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        bodyClassName='p-0'
      >
        <Card
          sx={{
            width: '100%',
            margin: 'auto',
            boxShadow: 'none',
            maxHeight: '80vh',
            overflowY: 'auto',
            padding: '20px 24px'
          }}
        >
          <Box sx={{ marginTop: '1rem' }}>
            {(isDashboard ? cellularGWUsageSummaryFetchingGraph : cellularUsageDataFetchingGraph) ? (
              <Loader />
            ) : chartData ? (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            ) : (
              <>
                <Typography
                  color='#3D4854'
                  fontWeight={400}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: getColorCode('redToBlue')
                  }}
                  height={'20vh'}
                >
                  There are no Gateways using cellular connection that is associated to this MSO.
                </Typography>
              </>
            )}
          </Box>

          {cellularGWUsageStats?.length !== 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                fontSize: '1rem',
                fontWeight: 700
              }}
            >
              <Typography color='#3D4854' fontWeight={700}>
                Last Updated:{' '}
              </Typography>
              {(isDashboard ? cellularGWUsageStatsFetchingGraph : cellularUsageDataFetchingGraph) ? (
                <Skeleton variant='text' animation='wave' width={'150px'} height={32} />
              ) : (
                moment
                  .utc(
                    isDashboard
                      ? cellularGWUsageStats?.at(-1)?.time_interval
                      : cellularUsageGraphData?.at(-1)?.timestamp
                  )
                  .local()
                  .format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
              )}
            </Box>
          )}

          <Card
            sx={{
              mt: 2,
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '0.5px solid #b4b8bd',
              boxShadow: 'none',
              borderRadius: '15px'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Box>
                <Typography color='#5D6670'>{'Download Usage'}</Typography>
                <Typography fontSize={'23px'} color={getColorCode('DownloadUsage')} fontWeight={700}>
                  {(isDashboard ? cellularSummaryFetching : isLoading) ? (
                    <Skeleton variant='text' animation='wave' width={'auto'} height={32} />
                  ) : (
                    convertMBSpeedParameter(
                      isDashboard ? cellularSummaryData?.download_data_mb || 0 : Number(data?.download_data_mb) || 0,
                      2
                    )
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography color='#5D6670'>{'Upload Usage'}</Typography>
                <Typography fontSize={'23px'} color={getColorCode('UploadUsage')} fontWeight={700}>
                  {(isDashboard ? cellularSummaryFetching : isLoading) ? (
                    <Skeleton variant='text' animation='wave' width={'auto'} height={32} />
                  ) : (
                    convertMBSpeedParameter(
                      isDashboard ? cellularSummaryData?.upload_data_mb || 0 : Number(data?.upload_data_mb) || 0,
                      2
                    )
                  )}
                </Typography>
              </Box>
            </Box>
            {isDashboard && FilterData !== 1 && (
              <div>
                <Typography color='#5D6670'>Devices Count</Typography>
                <Typography fontSize={'23px'} color={getColorCode('DeviceUsage')} fontWeight={700}>
                  {(isDashboard ? cellularSummaryFetching : isLoading) ? (
                    <Skeleton variant='text' animation='wave' width={'auto'} height={32} />
                  ) : (
                    `${cellularSummaryData?.device_count || 0}`
                  )}
                </Typography>
              </div>
            )}
          </Card>

          <CellularUsageTable
            filteredColumns={filteredColumns}
            tableData={tableData}
            isFetching={isDashboard ? cellularGWUsageSummaryFetchingGraph : cellularUsageDataFetchingGraph}
          />
        </Card>
      </CommonModal>
    </>
  )
}

export default CellularModal
