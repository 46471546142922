/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from '@mui/material'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import OnlineOfflineImage from '../../../../common-images/onlineOfflineImage'
import WiFiCoverage1 from '../../../../common-images/systemOverview/coverage1'
import Environment from '../../../../common-images/systemOverview/environment'
import InternetConnection from '../../../../common-images/systemOverview/internetConnection'
import NetWorkSpeed from '../../../../common-images/systemOverview/networkSpeed'
import Problem from '../../../../common-images/systemOverview/problem'
import TelemetryData from '../../../../common-images/systemOverview/telemetry'
import TriangleArrow from '../../../../common-images/triangle-arrow'
import { useAllActiveAlertsForGateway } from '../../../../hook/useAlerts'
import { useEnvironmentHealth } from '../../../../hook/useEnvironmentHealth'
import { useGatewayStats } from '../../../../hook/useGatewayStats'
import { useSpeedtest } from '../../../../hook/useSpeedtest'
import { useWifiCoverage } from '../../../../hook/useWifiCoverage'
import { commonCondition } from '../../../../lib/utils'
import { useSessionContex } from '../../../../SessionContex'
import { getColorCode } from '../../../../utils/helper'
import { TransformedSpeedDataFunction } from '../../../../utils/lib/utils'
import { customerSystemOverviewData } from './data'

const SystemOverview = ({ gatewayInfo, gatewayFeaching }: any) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const itemsPerPage = 8 // Number of items to display per page
  const currentPage = 1
  // const [currentPage, setCurrentPage] = useState(1)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [wifiCoverage, setWifiCoverage] = useState<any>({
    subHeading: 'N/A',
    status: '',
    value: '',
    target: ''
  })
  const [netWorkSpeed, setNetWorkSpeed] = useState({ status: '', value: '', target: '' })
  const [getWayInfo, setGetWayInfo] = useState({ parent_gw_uid: '', dev_mesh_role: '', wan_mac: '', gw_uid: '' })
  const [environmentHealth, setEnvironmentHealth] = useState({
    subHeading: 'N/A',
    status: '',
    value: '',
    target: ''
  })
  const [sortOrder, setSortOrder] = useState('none') // Default sorting order is ascending

  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  // const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  const chartfromDate = commonCondition
    ? moment.utc(moment.tz(moment().subtract(20, 'hours'), timeZone)).toISOString()
    : moment.utc(moment.tz(moment().subtract(20, 'hours'), timeZone)).format('YYYYMMDDTHH:mm:ss')
  const charttillDate = commonCondition
    ? moment.utc(moment.tz(moment(), timeZone)).toISOString()
    : moment.utc(moment.tz(moment(), timeZone)).format('YYYYMMDDTHH:mm:ss')
  const { data: internetandTelemetryData, refetch } = useGatewayStats(
    chartfromDate,
    charttillDate,
    gatewayInfo?.gw_uid,
    '1'
  )
  // const { data: gatewayInfo, isLoading: allGetWayLoading, refetch: refetchAllGetWay }: any = useGatewayInfo(gw_uid)
  const { data: dataWifiCoverage, refetch: wifiCoverageRefetch, error: errorWifiCoverage } = useWifiCoverage(getWayInfo)
  const {
    data: environmentHealthData,
    refetch: environmentHealthRefetch,
    error: errorEnvironmentHealth
  }: any = useEnvironmentHealth(getWayInfo)
  const defaultFilter = moment().startOf('day').toISOString()

  const { data: netWorkSpeedData, refetch: networkspeedrefetch } = useSpeedtest(gatewayInfo?.gw_uid, defaultFilter)
  const { data: alertData, refetch: alertRefetch } = useAllActiveAlertsForGateway(gatewayInfo?.gw_uid)

  useEffect(() => {
    if (!!gatewayInfo?.dev_mesh_role) {
      setGetWayInfo({
        dev_mesh_role: gatewayInfo?.dev_mesh_role,
        parent_gw_uid: gatewayInfo?.parent_gw_uid,
        wan_mac: gatewayInfo?.wan_mac,
        gw_uid: gatewayInfo?.gw_uid
      })
    }
  }, [gatewayInfo])

  useEffect(() => {
    if (!!gatewayInfo && getWayInfo.dev_mesh_role !== '') {
      wifiCoverageRefetch()
      environmentHealthRefetch()
    }
  }, [getWayInfo])

  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    } else {
      if (gatewayInfo?.gw_uid !== undefined) {
        setIsPageLoading(true)
        refetch()
        if (gatewayInfo?.gw_uid !== '') {
          alertRefetch()
          networkspeedrefetch()
        }
      }
    }
  }, [gatewayInfo?.gw_uid])

  useEffect(() => {
    if (reCall) {
      if (gatewayInfo?.gw_uid !== undefined) {
        setIsPageLoading(true)
      }
    }
  }, [reCall])

  useEffect(() => {
    if (!isPageLoading) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isPageLoading])

  useEffect(() => {
    const wifi_env_health = environmentHealthData?.length > 0 ? environmentHealthData[0]?.webapp_wifi_env_health : null
    if (gatewayInfo?.is_online) {
      if (typeof environmentHealthData === 'number') {
        if (environmentHealthData < 30) {
          setEnvironmentHealth({
            subHeading: 'Excellent WiFi environment',
            status: 'Good',
            value: `${environmentHealthData}`,
            target: '< 30'
          })
        } else if (environmentHealthData > 30 && environmentHealthData <= 70) {
          setEnvironmentHealth({
            subHeading: 'Wifi environment is Normal',
            status: 'Normal',
            value: `${environmentHealthData}`,
            target: '> 30'
          })
        } else if (environmentHealthData > 70) {
          setEnvironmentHealth({
            subHeading: 'High Congestion',
            status: 'High congestion',
            value: `${environmentHealthData}`,
            target: '> 70'
          })
        }
      } else {
        if (environmentHealthData === null) {
          setEnvironmentHealth({
            subHeading: 'N/A',
            status: 'N/A',
            value: 'N/A',
            target: 'N/A'
          })
        }
        if (environmentHealthData) {
          if (wifi_env_health === null) {
            setEnvironmentHealth({
              subHeading: 'N/A',
              status: 'N/A',
              value: 'N/A',
              target: 'N/A'
            })
          } else if (wifi_env_health < 30) {
            setEnvironmentHealth({
              subHeading: 'Excellent WiFi environment',
              status: 'Good',
              value: `${wifi_env_health}`,
              target: '< 30'
            })
          } else if (wifi_env_health > 30 && wifi_env_health <= 70) {
            setEnvironmentHealth({
              subHeading: 'Wifi environment is normal',
              status: 'Normal',
              value: `${wifi_env_health}`,
              target: '> 30'
            })
          } else if (wifi_env_health > 70) {
            setEnvironmentHealth({
              subHeading: 'High congestion',
              status: 'High congestion',
              value: `${wifi_env_health}`,
              target: '> 70'
            })
          }
        }

        if (!environmentHealth && errorEnvironmentHealth) {
          setEnvironmentHealth({
            subHeading: 'N/A',
            status: 'N/A',
            value: 'N/A',
            target: 'N/A'
          })
        }
        if (
          (environmentHealthData === null || !environmentHealthData) &&
          (wifi_env_health === null || !wifi_env_health)
        ) {
          setEnvironmentHealth({
            subHeading: 'N/A',
            status: 'N/A',
            value: 'N/A',
            target: 'N/A'
          })
        }
      }
    } else {
      setEnvironmentHealth({
        subHeading: 'N/A',
        status: 'N/A',
        value: 'N/A',
        target: 'N/A'
      })
    }
  }, [environmentHealthData, errorEnvironmentHealth])

  useEffect(() => {
    const wifi_cov = dataWifiCoverage?.webapp_wifi_coverage
    if (gatewayInfo?.is_online) {
      if (typeof dataWifiCoverage !== 'number') {
        if (!dataWifiCoverage && errorWifiCoverage) {
          setWifiCoverage({
            subHeading: 'N/A',
            status: 'N/A',
            value: 'N/A',
            target: 'N/A'
          })
        }
        if (dataWifiCoverage === null) {
          setWifiCoverage({
            subHeading: 'N/A',
            status: 'N/A',
            value: 'N/A',
            target: 'N/A'
          })
        }
        if (dataWifiCoverage) {
          if (wifi_cov === null) {
            setWifiCoverage({
              subHeading: 'N/A',
              status: 'N/A',
              value: 'N/A',
              target: 'N/A'
            })
          } else if (wifi_cov < 10) {
            setWifiCoverage({ subHeading: 'Poor WiFi coverage', status: 'Poor', value: `${wifi_cov}`, target: '>20' })
          } else if (wifi_cov > 10 && wifi_cov < 20) {
            setWifiCoverage({
              subHeading: 'WiFi coverage is Normal',
              status: 'Average',
              value: `${wifi_cov}`,
              target: '>20'
            })
          } else if (wifi_cov > 20) {
            setWifiCoverage({
              subHeading: 'WiFi coverage is Good',
              status: 'Good',
              value: `${wifi_cov}`,
              target: '>20'
            })
          }
        }
      } else {
        if (dataWifiCoverage) {
          if (dataWifiCoverage === null) {
            setWifiCoverage({
              subHeading: 'N/A',
              status: 'N/A',
              value: 'N/A',
              target: 'N/A'
            })
          } else if (dataWifiCoverage < 10) {
            setWifiCoverage({
              subHeading: 'Poor WiFi Coverage',
              status: 'Poor',
              value: `${dataWifiCoverage}`,
              target: '>20'
            })
          } else if (dataWifiCoverage > 10 && dataWifiCoverage < 20) {
            setWifiCoverage({
              subHeading: 'WiFi coverage is Normal',
              status: 'Average',
              value: `${dataWifiCoverage}`,
              target: '>20'
            })
          } else if (dataWifiCoverage > 20) {
            setWifiCoverage({
              subHeading: 'WiFi coverage is Good',
              status: 'Good',
              value: `${dataWifiCoverage}`,
              target: '>20'
            })
          }
        }
      }
    } else {
      setWifiCoverage({
        subHeading: 'N/A',
        status: 'N/A',
        value: 'N/A',
        target: 'N/A'
      })
    }
  }, [dataWifiCoverage, errorWifiCoverage])

  useEffect(() => {
    if (gatewayInfo?.is_online) {
      if (!!netWorkSpeedData && netWorkSpeedData?.length > 0) {
        const transformedData = TransformedSpeedDataFunction(netWorkSpeedData)

        const work_speed = commonCondition
          ? transformedData[0]?.speedtest?.params?.max_download_speed
          : netWorkSpeedData[0]?.speedtest?.max_download_speed ||
            netWorkSpeedData[0]?.speedtest?.params?.max_download_speed

        if (work_speed >= 1 && work_speed <= 100) {
          setNetWorkSpeed({ status: 'Poor', value: `${Math.round(work_speed)}`, target: '1 Gbps' })
        } else if (work_speed > 100 && work_speed <= 400) {
          setNetWorkSpeed({ status: 'Average', value: `${Math.round(work_speed)}`, target: '1 Gbps' })
        } else if (work_speed > 400) {
          setNetWorkSpeed({ status: 'Good', value: `${Math.round(work_speed)}`, target: '1 Gbps' })
        } else if (work_speed === null) {
          setNetWorkSpeed({ status: 'N/A', value: 'N/A', target: 'N/A' })
        }
      }

      if (netWorkSpeedData?.length === 0 || netWorkSpeedData === null) {
        setNetWorkSpeed({ status: 'N/A', value: 'N/A', target: 'N/A' })
      }
    } else {
      setNetWorkSpeed({ status: 'N/A', value: 'N/A', target: 'N/A' })
    }
  }, [netWorkSpeedData])

  const alertActualData: any =
    alertData !== undefined && alertData.lenght > 0
      ? alertData?.map((item: any) => {
          return item.alert_level === 'Critical'
        })
      : []

  useEffect(() => {
    if (gatewayFeaching) {
      setIsPageLoading(true)
    } else {
      if (
        isPageLoading &&
        // internetandTelemetryData &&
        environmentHealth &&
        netWorkSpeed &&
        wifiCoverage
        // alertActualData
      ) {
        setTimeout(() => {
          setIsPageLoading(false)
        }, 2000)
      }
    }
  }, [
    gatewayFeaching,
    isPageLoading,
    internetandTelemetryData,
    environmentHealth,
    wifiCoverage,
    alertActualData,
    netWorkSpeed
  ])

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  // Filter the data to display only the items for the current page
  const currentData = customerSystemOverviewData.slice(startIndex, endIndex)

  // Sort the data based on the sortOrder
  const sortedData = [...currentData]

  if (sortOrder === 'ascending') {
    sortedData.sort((a, b) => {
      if (a.status < b.status) return -1
      if (a.status > b.status) return 1
      return 0
    })
  } else if (sortOrder === 'descending') {
    sortedData.sort((a, b) => {
      if (a.status > b.status) return -1
      if (a.status < b.status) return 1
      return 0
    })
  }

  const handleSortStatus = () => {
    if (sortOrder === 'ascending') {
      setSortOrder('descending')
    } else if (sortOrder === 'descending') {
      setSortOrder('none') // Set it to 'none' to remove the filter
    } else {
      setSortOrder('ascending') // Cycle back to ascending
    }
  }

  return (
    <>
      {gatewayInfo && !gatewayInfo?.is_online && (
        <div className='mt-2 flex text-center items-center justify-center' style={{ color: getColorCode('redToBlue') }}>
          Data not available, as Device is Offline
        </div>
      )}
      <>
        <div className='overview-table-height'>
          <div className='d-flex overview-table mt-3'>
            <div className='trends-div-cls'>Trends</div>
            <div className='other-div-cls' onClick={handleSortStatus}>
              Status
              {/* {sortOrder === 'ascending' ? (
                <ArrowUpward /> // MUI arrow up icon
              ) : sortOrder === 'descending' ? (
                <ArrowDownward /> // MUI arrow down icon
              ) : null} */}
            </div>
            <div className='other-div-cls'>Value</div>
            <div className='other-div-cls'>Target</div>
            {/* <div className='trends-line-div-cls'>Trend Line</div>
          <div className='indicator-div-cls'>Indicator</div> */}
          </div>
          {/* 0 Index Internet Connection */}
          <div key='0' className='overview-details-div d-flex'>
            <div className='d-flex align-items-center trends-div-cls d-flex'>
              <div className='d-flex icon-cls'>
                <InternetConnection width='18px' height='18px' />
              </div>
              <div className='d-flex flex-column ms-2 h-auto'>
                <label className='table-title'>Internet Connection</label>
                <p className='table-desc m-0'>
                  {!isPageLoading && gatewayInfo ? (
                    gatewayInfo?.is_online === true ? (
                      'WiFi Network is Online'
                    ) : (
                      'WiFi Network is Offline'
                    )
                  ) : (
                    <Skeleton variant='text' animation='wave' width={80} height={15} />
                  )}
                </p>
              </div>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              {!isPageLoading ? (
                <>
                  {gatewayInfo?.is_online ? (
                    internetandTelemetryData && internetandTelemetryData?.length >= 0 ? (
                      <OnlineOfflineImage fillColor={getColorCode('Online')} width='14px' height='14px' />
                    ) : (
                      <OnlineOfflineImage fillColor={getColorCode('redToYellow')} width='14px' height='14px' />
                    )
                  ) : (
                    <OnlineOfflineImage fillColor={getColorCode('Offline')} width='14px' height='14px' />
                  )}
                  <label className='ps-2 other-detail-text'>
                    {gatewayInfo?.is_online
                      ? internetandTelemetryData
                        ? internetandTelemetryData?.length >= 0
                          ? 'Good'
                          : 'Poor'
                        : 'N/A'
                      : 'N/A'}
                  </label>
                </>
              ) : (
                <Skeleton variant='text' animation='wave' width={80} height={15} />
              )}
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading ? (
                  gatewayInfo?.is_online ? (
                    internetandTelemetryData ? (
                      internetandTelemetryData?.length >= 0 ? (
                        'Online'
                      ) : (
                        'Offline'
                      )
                    ) : (
                      'N/A'
                    )
                  ) : (
                    'N/A'
                  )
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading ? (
                  gatewayInfo?.is_online ? (
                    internetandTelemetryData ? (
                      internetandTelemetryData?.length >= 0 ? (
                        'Online'
                      ) : (
                        'Offline'
                      )
                    ) : (
                      'N/A'
                    )
                  ) : (
                    'N/A'
                  )
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
          </div>
          {/* 1 Index Environment */}
          <div key='1' className='overview-details-div d-flex'>
            <div className='d-flex align-items-center trends-div-cls d-flex'>
              <div className='d-flex icon-cls'>
                <Environment width='18px' height='18px' />
              </div>
              <div className='d-flex flex-column ms-2 h-auto'>
                <label className='table-title'>Environment</label>
                <p className='table-desc m-0'>
                  {!isPageLoading && environmentHealth && environmentHealth.status !== '' ? (
                    environmentHealth.subHeading
                  ) : (
                    <Skeleton variant='text' animation='wave' width={80} height={15} />
                  )}
                </p>
              </div>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              {!isPageLoading && environmentHealth && environmentHealth.status !== '' ? (
                <>
                  <OnlineOfflineImage fillColor={getColorCode(environmentHealth.status)} width='14px' height='14px' />
                  <label className='ps-2 other-detail-text'>{environmentHealth.status}</label>
                </>
              ) : (
                <Skeleton variant='text' animation='wave' width={80} height={15} />
              )}
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading && environmentHealth && environmentHealth.value !== '' ? (
                  environmentHealth.value
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading && environmentHealth && environmentHealth.target !== '' ? (
                  environmentHealth.target
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
          </div>
          {/* 2 Stability */}
          {/* <div key='2' className='overview-details-div d-flex'>
          <div className='d-flex align-items-center trends-div-cls d-flex'>
            <div className='d-flex icon-cls'>
              <Stability width='17px' height='13px' />
            </div>
            <div className='d-flex flex-column ms-2 h-auto'>
              <label className='table-title'>Stability</label>
              <p className='table-desc m-0'>Health Optimization</p>
            </div>
          </div>
          <div className='other-div-cls d-flex align-items-center'>
            <TriangleArrow fillColor='#F1AD2A' width='16px' height='10px' />
            <label className='ps-2 other-detail-text'>Warning</label>
          </div>
          <div className='other-div-cls d-flex align-items-center'>
            <label className='other-detail-text'>70%</label>
          </div>
          <div className='other-div-cls d-flex align-items-center'>
            <label className='other-detail-text'>100%</label>
          </div>
        </div> */}
          {/* 3 Network Speed */}
          <div key='3' className='overview-details-div d-flex'>
            <div className='d-flex align-items-center trends-div-cls d-flex'>
              <div className='d-flex icon-cls'>
                <NetWorkSpeed width='20px' height='16px' />
              </div>
              <div className='d-flex flex-column ms-2 h-auto'>
                <label className='table-title'>Network Speed</label>
                <p className='table-desc m-0'>
                  {!isPageLoading && netWorkSpeed && netWorkSpeed.status !== '' ? (
                    <>{`Network Speed is ${netWorkSpeed.status}`}</>
                  ) : (
                    <Skeleton variant='text' animation='wave' width={80} height={15} />
                  )}
                </p>
              </div>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              {!isPageLoading && netWorkSpeed && netWorkSpeed.status !== '' ? (
                <>
                  <OnlineOfflineImage fillColor={getColorCode(netWorkSpeed.status)} width='14px' height='14px' />
                  <label className='ps-2 other-detail-text'>{netWorkSpeed.status}</label>
                </>
              ) : (
                <Skeleton variant='text' animation='wave' width={80} height={15} />
              )}
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading && netWorkSpeed && netWorkSpeed.value !== '' ? (
                  netWorkSpeed.value
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading && netWorkSpeed && netWorkSpeed.target !== '' ? (
                  netWorkSpeed.target
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
          </div>
          {/* 4 WiFi Coverage */}
          <div key='4' className='overview-details-div d-flex'>
            <div className='d-flex align-items-center trends-div-cls d-flex'>
              <div className='d-flex icon-cls'>
                <WiFiCoverage1 width='20px' height='15px' />
              </div>
              <div className='d-flex flex-column ms-2 h-auto'>
                <label className='table-title'>WiFi Coverage</label>
                {!isPageLoading && !!wifiCoverage.subHeading ? (
                  <p className='table-desc m-0'>{wifiCoverage.subHeading}</p>
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </div>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              {!isPageLoading && wifiCoverage && wifiCoverage.status !== '' ? (
                <>
                  <OnlineOfflineImage fillColor={getColorCode(wifiCoverage.status)} width='14px' height='14px' />
                  <label className='ps-2 other-detail-text'>{wifiCoverage.status}</label>
                </>
              ) : (
                <Skeleton variant='text' animation='wave' width={80} height={15} />
              )}
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading && wifiCoverage && wifiCoverage.value !== '' ? (
                  wifiCoverage.value
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading && wifiCoverage && wifiCoverage.target !== '' ? (
                  wifiCoverage.target
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
          </div>
          {/* 5 Telemetry Data */}
          <div key='5' className='overview-details-div d-flex'>
            <div className='d-flex align-items-center trends-div-cls d-flex'>
              <div className='d-flex icon-cls'>
                <TelemetryData width='19px' height='20px' />
              </div>
              <div className='d-flex flex-column ms-2 h-auto'>
                <label className='table-title'>Telemetry Data</label>
                <p className='table-desc m-0'>
                  {!isPageLoading ? (
                    gatewayInfo?.is_online ? (
                      internetandTelemetryData ? (
                        internetandTelemetryData && internetandTelemetryData?.length > 0 ? (
                          'Access to Telemetry Data is Good'
                        ) : (
                          'Access to Telemetry Data is Poor'
                        )
                      ) : (
                        'Access to Telemetry Data is N/A'
                      )
                    ) : (
                      'Access to Telemetry Data is N/A'
                    )
                  ) : (
                    <Skeleton variant='text' animation='wave' width={80} height={15} />
                  )}
                </p>
              </div>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              {!isPageLoading ? (
                <>
                  {gatewayInfo?.is_online && internetandTelemetryData ? (
                    internetandTelemetryData && internetandTelemetryData?.length > 0 ? (
                      <OnlineOfflineImage fillColor={getColorCode('Online')} width='14px' height='14px' />
                    ) : (
                      <OnlineOfflineImage fillColor={getColorCode('Offline')} width='14px' height='14px' />
                    )
                  ) : (
                    <OnlineOfflineImage fillColor={getColorCode('Offline')} width='14px' height='14px' />
                  )}
                  <label className='ps-2 other-detail-text'>
                    {gatewayInfo?.is_online
                      ? internetandTelemetryData
                        ? internetandTelemetryData?.length > 0
                          ? 'Good'
                          : 'Poor'
                        : 'N/A'
                      : 'N/A'}
                  </label>
                </>
              ) : (
                <Skeleton variant='text' animation='wave' width={80} height={15} />
              )}
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading ? (
                  gatewayInfo?.is_online ? (
                    internetandTelemetryData ? (
                      internetandTelemetryData?.length > 0 ? (
                        'YES'
                      ) : (
                        'NO'
                      )
                    ) : (
                      'N/A'
                    )
                  ) : (
                    'N/A'
                  )
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
            <div className='other-div-cls d-flex align-items-center'>
              <label className='other-detail-text'>
                {!isPageLoading ? (
                  gatewayInfo?.is_online ? (
                    internetandTelemetryData ? (
                      internetandTelemetryData?.length > 0 ? (
                        'YES'
                      ) : (
                        'NO'
                      )
                    ) : (
                      'N/A'
                    )
                  ) : (
                    'N/A'
                  )
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </label>
            </div>
          </div>
          {/* 6 Telemetry Data */}
          {process.env.REACT_APP_MODE === 'hitron' ? (
            <></>
          ) : (
            <div key='6' className='overview-details-div d-flex'>
              <div className='d-flex align-items-center trends-div-cls d-flex'>
                <div className='d-flex icon-cls'>
                  <Problem width='18px' height='18px' />
                </div>
                <div className='d-flex flex-column ms-2 h-auto'>
                  <label className='table-title'>Problems</label>
                  <p className='table-desc m-0'>
                    {!isPageLoading ? (
                      gatewayInfo?.is_online ? (
                        alertActualData &&
                        (alertActualData && alertActualData?.length > 0
                          ? 'System is operating below expectations'
                          : 'System is operating normal')
                      ) : (
                        'System is operating N/A'
                      )
                    ) : (
                      <Skeleton variant='text' animation='wave' width={80} height={15} />
                    )}
                  </p>
                </div>
              </div>
              <div className='other-div-cls d-flex align-items-center'>
                {!isPageLoading && alertActualData ? (
                  <>
                    <TriangleArrow
                      fillColor={getColorCode(
                        alertActualData && alertActualData?.length > 0 ? 'Critical' : 'No issues'
                      )}
                      width='16px'
                      height='10px'
                    />
                    <label className='ps-2 other-detail-text'>
                      {gatewayInfo?.is_online
                        ? alertActualData && alertActualData?.length > 0
                          ? 'Critical'
                          : 'No issues'
                        : 'N/A'}
                    </label>
                  </>
                ) : (
                  <Skeleton variant='text' animation='wave' width={80} height={15} />
                )}
              </div>
              <div className='other-div-cls d-flex align-items-center'>
                <label className='other-detail-text'>
                  {!isPageLoading ? (
                    gatewayInfo?.is_online ? (
                      alertActualData && (alertActualData?.length > 0 ? alertActualData?.length : 0)
                    ) : (
                      'N/A'
                    )
                  ) : (
                    <Skeleton variant='text' animation='wave' width={80} height={15} />
                  )}
                </label>
              </div>
              <div className='other-div-cls d-flex align-items-center'>
                <label className='other-detail-text'>
                  {!isPageLoading ? (
                    gatewayInfo?.is_online ? (
                      alertActualData && 0
                    ) : (
                      'N/A'
                    )
                  ) : (
                    <Skeleton variant='text' animation='wave' width={80} height={15} />
                  )}
                </label>
              </div>
            </div>
          )}

          {/* {sortedData &&
          sortedData?.map((activity: any, index: any) => (
            <div key={index} className='overview-details-div d-flex'>
              <div className='d-flex align-items-center trends-div-cls d-flex'>
                <div className='d-flex icon-cls'>
                  {activity.icon === 'internet' ? (
                    <InternetConnection width='18px' height='18px' />
                  ) : activity.icon === 'environment' ? (
                    <Environment width='18px' height='18px' />
                  ) : activity.icon === 'stability' ? (
                    <Stability width='17px' height='13px' />
                  ) : activity.icon === 'network' ? (
                    <NetWorkSpeed width='20px' height='16px' />
                  ) : activity.icon === 'coverage1' ? (
                    <WiFiCoverage1 width='20px' height='15px' />
                  ) : activity.icon === 'coverage2' ? (
                    <WiFiCoverage2 width='17px' height='14px' />
                  ) : activity.icon === 'telemetry' ? (
                    <TelemetryData width='19px' height='20px' />
                  ) : (
                    <Problem width='18px' height='18px' />
                  )}
                </div>
                <div className='d-flex flex-column ms-2 h-auto'>
                  <label className='table-title'>{activity.title}</label>
                  <p className='table-desc m-0'>{activity.description}</p>
                </div>
              </div>
              <div className='other-div-cls d-flex align-items-center'>
                {activity.status === 'online' ? (
                  <OnlineOfflineImage fillColor='#01BE4F' width='14px' height='14px' />
                ) : activity.status === 'critical' ? (
                  <TriangleArrow fillColor='#F27A7A' width='16px' height='10px' />
                ) : (
                  <TriangleArrow fillColor='#F1AD2A' width='16px' height='10px' />
                )}
                <label className='ps-2 other-detail-text'>
                  {activity.status === 'online' ? 'Good' : activity.status === 'critical' ? 'Critical' : 'Warning'}
                </label>
              </div>
              <div className='other-div-cls d-flex align-items-center'>
                <label className='other-detail-text'>{activity.value}</label>
              </div>
              <div className='other-div-cls d-flex align-items-center'>
                <label className='other-detail-text'>{activity.target}</label>
              </div>
               <div className='trends-line-div-cls d-flex align-items-center'>
                <QoEPerformanceChart />
              </div>
              <div className='d-flex align-items-center indicator-div-cls'>
                <div
                  className={`${
                    activity.indicator === 'critical' ? 'active-critical' : ''
                  } indicator-progress indicator-progress-critical me-1`}
                ></div>
                <div
                  className={`${
                    activity.indicator === 'warning' ? 'active-warning' : ''
                  } indicator-progress indicator-progress-warning me-1`}
                ></div>
                <div
                  className={`${
                    activity.indicator === 'success' ? 'active-success' : ''
                  } indicator-progress-success me-1`}
                ></div>
                <div
                  className={`${
                    activity.indicator === 'warning-last' ? 'active-warning-last' : ''
                  } indicator-progress indicator-progress-warning-last me-1`}
                ></div>
                <div
                  className={`${
                    activity.indicator === 'critical-last' ? 'active-critical-last' : ''
                  } indicator-progress indicator-progress-critical-last`}
                ></div>
              </div> 
            </div>
          ))} */}
        </div>
        {/* <div className='fixed-pagination-container'>
          <Pagination
            onePage={8}
            totalPages={6}
            handleSetCurrentCount={(newPage: any) => {
              setCurrentPage(newPage)
            }}
          />
        </div> */}
      </>
    </>
  )
}

export default SystemOverview
