import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SegmentArray } from '../../../components/GroupsTable/types'
import Loader from '../../../components/Loader'
import moment from 'moment'
import { getStatusColor } from '../../../lib/utils'

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold'
})

interface BatchSegmentInformationProps {
  segementList: SegmentArray
  isFetching: boolean
  isLoading: boolean
}

const BatchSegmentInformation = ({ segementList, isLoading, isFetching }: BatchSegmentInformationProps) => {
  return (
    <Box sx={{ mt: 5 }}>
      <h2 className='text-xl !font-bold text-[#1B2559] mb-2'>Segment Information</h2>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: '100%',
          maxHeight: '375px', // Adjust the height as needed
          overflowY: 'auto',
          overflowX: 'auto'
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>TIME CREATED</StyledTableCell>
              <StyledTableCell>SIZE</StyledTableCell>
              <StyledTableCell>SEGMENT ID</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>PROCESSING START</StyledTableCell>
              <StyledTableCell>PROCESSING END</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching || isLoading ? (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }} className='flex justify-center items-center w-full'>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : !segementList || segementList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                  No Segement Data Found!!!
                </TableCell>
              </TableRow>
            ) : (
              segementList &&
              segementList?.map((res, index: number) => {
                const segementCreatedTime = res.segment_created
                  ? moment.utc(res.segment_created).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                  : '-'
                const segementStartTime = res.start_time
                  ? moment.utc(res.start_time).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                  : '-'
                const segementEndTime = res.end_time
                  ? moment.utc(res.end_time).local().format(`${process.env.REACT_APP_DATE_FORMAT} h:mm A`)
                  : '-'
                return (
                  <TableRow key={index}>
                    <TableCell>{segementCreatedTime}</TableCell>
                    <TableCell>{res.gateway_count}</TableCell>
                    <TableCell>#{res.segment_id}</TableCell>
                    <TableCell style={{ color: getStatusColor(res.segment_status), fontWeight: 700 }}>
                      {res.segment_status}
                    </TableCell>
                    <TableCell>{segementStartTime}</TableCell>
                    <TableCell>{segementEndTime}</TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default BatchSegmentInformation
