import { Close } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import * as React from 'react'
import { useAddDevice } from '../../hook/useAllCustomers'
import { useSessionContex } from '../../SessionContex'
import { getColorCode } from '../../utils/helper'

const DeleteDevice = ({
  handleClose,
  deviceArray,
  setDeviceArray,
  setCallDeviceDetail,
  setSnackBarInfo,
  setReCall
}: any) => {
  const { openModal, setOpenModal } = useSessionContex()
  const [disable, setDisable] = React.useState<any>(false)

  const addDevice = useAddDevice()

  const onSubmit = () => {
    setDisable(true)
    const dataNew = deviceArray.toString()
    const payload = {
      _white_label: '',
      _gateway_list: `{${dataNew}}`
    }

    addDevice.mutate(payload, {
      onSuccess: (data: any) => {
        //  if (deviceArray?.length !== data.length) {

        //     setMessageArray(data)
        //     setOpenListModal(true)
        //   } else {
        //     setReCall(true)
        //     setCallDeviceDetail(true)
        //     handleClose()
        //   }
        if (data.length !== 0) {
          setSnackBarInfo({
            isOpen: true,
            message: 'Device removed successfully',
            type: 'Closed'
          })
          handleClose()
          setReCall(true)
          setCallDeviceDetail(true)
        }
        setTimeout(() => {
          setDisable(false)
          setDeviceArray([])
        }, 200)
        if (data === 401) {
          !openModal && setOpenModal(true)
        }
      },
      onError: (error: any) => {
        setSnackBarInfo({
          isOpen: true,
          message: `${error ? error : `Failed to remove Device.`}`,
          type: 'redToRed'
        })
      }
    })
  }

  return (
    <Box>
      <Box className='border-bottom' textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2}>
        <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>
          Are you sure you want to remove device(s) from this MSO?
        </h3>
        <Close
          className='cursor-pointer'
          onClick={() => {
            handleClose()
          }}
        />
      </Box>
      <Box className='pb-3  pt-1 text-center text-lg' paddingLeft={5} paddingRight={5}>
        Please note, after removing, this device(s) will not be visible in the List of Devices of this customer. You can
        always add it back
        <div className='mt-4'>
          <Button
            type='submit'
            variant='contained'
            size='medium'
            sx={{
              marginRight: '10px',
              background: getColorCode('infoBlue'),
              '&:hover': {
                backgroundColor: getColorCode('infoBlue')
              }
            }}
            onClick={onSubmit}
            disabled={disable}
          >
            OK
          </Button>
        </div>
      </Box>
      {/* <CustomerModal
        open={openListModal}
        handleClose={() => {
          setOpenListModal(false)
          setMessageArray([])
          setReCall(true)
          setCallDeviceDetail(true)
          handleClose()
        }}
      >
        <RemoveSuccess
          handleClose={() => {
            setOpenListModal(false)
            setMessageArray([])
            setReCall(true)
            setCallDeviceDetail(true)
            handleClose()
          }}
          data={messageArray}
        />
      </CustomerModal> */}
    </Box>
  )
}
export default DeleteDevice
