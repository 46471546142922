import { Close } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import * as React from 'react'
import { useKeyClockDeleteMSOUser } from '../../hook/useAllCustomers'
import { useSessionContex } from '../../SessionContex'
import { getColorCode } from '../../utils/helper'

const DeleteMSOUser = ({ handleClose, setSnackBarInfo, MSOData, editData, MSOUserFetch }: any) => {
  const { openModal, setOpenModal } = useSessionContex()
  const [disable, setDisable] = React.useState<any>(false)

  const deleteKeyClockUser = useKeyClockDeleteMSOUser()

  const onSubmit = () => {
    setDisable(true)

    deleteKeyClockUser.mutate(
      { group_name: MSOData?.white_label, id: editData?.id },
      {
        onSuccess: (data: any) => {
          if (data === 401) {
            !openModal && setOpenModal(true)
          }
          setSnackBarInfo({
            isOpen: true,
            message: 'MSO Admin deleted successfully',
            type: 'Closed'
          })
          setTimeout(() => {
            MSOUserFetch()
            handleClose()
            setDisable(false)
          }, 500)
        },
        onError: (error: any) => {
          setSnackBarInfo({
            isOpen: true,
            message: error?.response?.data
              ? error?.response?.data?.errorMessage
              : error?.message || `Sorry, Can't removed`,
            type: 'redToRed'
          })
          setDisable(false)
        }
      }
    )
  }

  return (
    <Box className='rounded'>
      <Box textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2} className='border-bottom'>
        <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>
          Are you sure you want to delete this MSO User?
        </h3>
        <Close
          className='cursor-pointer'
          onClick={() => {
            handleClose()
          }}
        />
      </Box>
      <Box className='pb-3 f8fe] pt-1 text-center' paddingLeft={5} paddingRight={5}>
        <div className='mt-4 mb-3'>
          <Button
            type='submit'
            variant='contained'
            size='medium'
            sx={{
              marginRight: '10px',
              background: getColorCode('infoBlue'),
              '&:hover': {
                backgroundColor: getColorCode('infoBlue')
              }
            }}
            onClick={onSubmit}
            disabled={disable}
          >
            {'DELETE'}
          </Button>
        </div>
      </Box>
    </Box>
  )
}
export default DeleteMSOUser
