import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import HeaderLogo from '../../../common-images/app-logo/header-logo'
import SidebarCollapseLogo from '../../../common-images/app-logo/sidebar-collapes-logo'
import EarthLinkLogoCollapse from '../../../common-images/earthlink-logo/earthlink-collapse-logo-2.png'
import EarthLinkLogo from '../../../common-images/earthlink-logo/earthlink-logo-2.png'
import EntryPointLogo from '../../../common-images/entry-point-logo/ORANGEBURG.png'
import EntryPointLogoCollapse from '../../../common-images/entry-point-logo/entry-point-collapse-logo.png'
import EntryPointLogo1 from '../../../common-images/entry-point-logo/entry-point-logo-1.png'
import HitronHeaderLogo from '../../../common-images/hitron-logo/header-logo'
import HitronSidebarCollapseLogo from '../../../common-images/hitron-logo/sidebar-collapes-hiton-logo'
import {
  default as hitronHeaderImg,
  default as hitronSidebarCollapseNewLogo
} from '../../../common-images/hitron-logo/small-logo.png'
import { useScreenWidth } from '../../../hook/useScreenWidth'
import Breadcrumbs from '../Breadcrumbs'
import RightSideBar from '../RightSideBar'

const HeaderSection = () => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
  const width = useScreenWidth()
  const location = useLocation()

  useEffect(() => {
    setScreenWidth(width)
  }, [width])

  return (
    <div className='headerWrapper'>
      {screenWidth > 1441 ? (
        <>
          {process.env.REACT_APP_MODE === 'local' &&
            process.env.REACT_APP_REALM !== 'entpnt' &&
            process.env.REACT_APP_REALM !== 'earthlink' && <HeaderLogo className={'headerWrapper-logo'} />}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM !== 'Hitron' && (
            <HitronHeaderLogo className={'headerWrapper-logo'} />
          )}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM === 'Hitron' && (
            <Box className={'headerWrapper-logo'}>
              <Box width={'50px'}>
                <img src={hitronHeaderImg} alt='' />
              </Box>
            </Box>
          )}
          {process.env.REACT_APP_REALM === 'earthlink' && (
            <Box className={'headerWrapper-logo'}>
              <Box width={'150px'}>
                <img src={EarthLinkLogo} alt='EarthLinkLogo1' />
              </Box>
            </Box>
          )}
          {process.env.REACT_APP_REALM === 'entpnt' && (
            <Box
              className={`headerWrapper-logo items-center justify-center ${
                location.pathname === '/privacypolicy' || location.pathname === '/termscondition' ? '!w-full !py-5' : ''
              }`}
            >
              <Box
                width={
                  location.pathname === '/privacypolicy' || location.pathname === '/termscondition' ? '100%' : '160px'
                }
              >
                <img
                  src={
                    location.pathname === '/privacypolicy' || location.pathname === '/termscondition'
                      ? EntryPointLogo
                      : EntryPointLogo1
                  }
                  alt='EntryPointLogo'
                />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {process.env.REACT_APP_MODE === 'local' &&
            process.env.REACT_APP_REALM !== 'entpnt' &&
            process.env.REACT_APP_REALM !== 'earthlink' && <SidebarCollapseLogo className={'headerWrapper-logo'} />}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM !== 'Hitron' && (
            <HitronSidebarCollapseLogo className={'headerWrapper-logo'} />
          )}
          {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM === 'Hitron' && (
            <Box className={'headerWrapper-logo'}>
              <Box width={'50px'}>
                <img src={hitronSidebarCollapseNewLogo} alt='' />
              </Box>
            </Box>
          )}
          {process.env.REACT_APP_REALM === 'earthlink' && (
            <Box className={'headerWrapper-logo'}>
              <Box width={'40px'}>
                <img src={EarthLinkLogoCollapse} alt='EarthLinkLogo1' />
              </Box>
            </Box>
          )}
          {process.env.REACT_APP_REALM === 'entpnt' && (
            <Box
              className={`headerWrapper-logo ${
                location.pathname === '/privacypolicy' || location.pathname === '/termscondition'
                  ? '!w-full !pl-[24px] !py-5'
                  : ''
              }`}
            >
              <Box
                width={
                  location.pathname === '/privacypolicy' || location.pathname === '/termscondition' ? '100%' : '40px'
                }
              >
                <img
                  src={
                    location.pathname === '/privacypolicy' || location.pathname === '/termscondition'
                      ? EntryPointLogo
                      : EntryPointLogoCollapse
                  }
                  alt='Entrypoint-logo'
                />
              </Box>
            </Box>
          )}
        </>
      )}

      {location.pathname !== '/privacypolicy' && location.pathname !== '/termscondition' && (
        <>
          <div className='headerActions'>
            {screenWidth > 1300 ? <Breadcrumbs /> : <span></span>}
            <RightSideBar />
          </div>
        </>
      )}
    </div>
  )
}

export default HeaderSection
