import React from 'react'
import { getColorCode } from '../../utils/helper'
import { Box, Stack } from '@mui/material'
import CountUp from 'react-countup'

interface NetworkBarProps {
  name: string
  count: number
  color: string
}

const NetworkBar = ({ name, count, color }: NetworkBarProps) => {
  return (
    <React.Fragment>
      <Stack
        direction={'row'}
        spacing={1}
        sx={{
          border: `2px solid ${!count || count === 0 ? getColorCode('grayToGray') : getColorCode('greenToGreen')}`,
          borderRadius: '4px',
          margin: '3px',
          justifyContent: 'space-between'
        }}
      >
        <Box
          className='capitalize'
          sx={{
            padding: '8px 20px',
            fontWeight: 700,
            color: '#000'
          }}
        >
          {name}
        </Box>

        <Box
          sx={{
            background: `${!count || count === 0 ? getColorCode('grayToGray') : getColorCode('greenToGreen')}`,
            paddingLeft: '10px',
            paddingRight: '10px',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '40px'
          }}
        >
          <CountUp start={0} end={count} duration={2} style={{ fontWeight: 700, fontSize: '1rem' }} />
        </Box>
      </Stack>
    </React.Fragment>
  )
}

export default NetworkBar
