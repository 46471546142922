import React from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, DialogProps, Breakpoint } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface CommonModalProps {
  open: boolean
  onClose?: () => void
  title: string | React.ReactNode
  maxWidth?: false | Breakpoint | undefined
  children?: React.ReactNode
  bodyClassName?: string
}

const CommonModal: React.FC<CommonModalProps> = ({
  open,
  onClose,
  title,
  children,
  maxWidth = 'sm',
  bodyClassName = ''
}) => {
  const handleClose: DialogProps['onClose'] = (event, reason) => {
    // Prevent closing when clicking outside or pressing Esc
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    onClose?.()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth
      // PaperProps={{
      //   sx: { borderRadius: '20px' }
      // }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '2px solid #E8E9EE'
        }}
      >
        {title}
        {onClose && (
          <IconButton onClick={onClose} size='small'>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={bodyClassName} style={{ padding: '15px auto' }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default CommonModal
