import { clsx, type ClassValue } from 'clsx'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { twMerge } from 'tailwind-merge'
import { ApiResponse } from '../../Pages/Customer/SpeedTest/type'

dayjs.extend(utc)
dayjs.extend(timezone)

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// export const IPv4Regex =
//   /^(10\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|172\.(1[6-9]|2[0-9]|3[0-1])\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|192\.168\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/
// (old v4 regex 10.0.0.0 to 10.255.255.255)

export const IPv4Regex =
  /^(10\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.(?:[1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4]?)|172\.(1[6-9]|2[0-9]|3[0-1])\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.(?:[1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4]?)|192\.168\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.(?:[1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4]?))$/ // new v4 regex 10.0.0.1 to 10.255.255.254

export const emailVal = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export function convertToUTC(timeStr: string) {
  return dayjs(timeStr, 'HH:mm:ss').utc().format('HH:mm:ss')
}

export function convertToLocal(utcTimeStr: string) {
  return dayjs.utc(utcTimeStr, 'HH:mm:ss').local().format('HH:mm:ss')
}

export const TransformedSpeedDataFunction = (data: ApiResponse[]) => {
  return data?.map((item: ApiResponse) => ({
    timestamp: item.timestamp,
    speedtest: {
      callid: item.callid,
      params: {
        ping: item.ping,
        jitter: item.jitter,
        distance: item.distance,
        server_id: item.server_id,
        server_url: item.server_url,
        server_name: item.server_name,
        server_sponsor: item.server_sponsor,
        avg_upload_speed: item.avg_upload_speed,
        max_upload_speed: item.max_upload_speed,
        max_download_speed: item.max_download_speed,
        average_download_speed: item.avg_download_speed
      },
      result: item.result,
      retcode: '0',
      timestamp: new Date(item.timestamp).toUTCString(),
      stderr_text: '',
      stdout_text: ''
    }
  }))
}
